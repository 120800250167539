import axiosInstance from './axiosConfig';
import axios from 'axios';

// Simulated login API call
export const login = async (credentials) => {
    const response = await axiosInstance.post('/api/v1/auth/login',credentials);

    return response.data;
};

// Logout function
export const logout = () => {
    // Clear all auth-related data
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("userData");

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("username");
};

// Check login status
export const checkLoginStatus = () => {
    return localStorage.getItem('isLoggedIn') === 'true';
};

// Add this with the other exports
export const getToken = () => {
    return localStorage.getItem('token');
};

/**
 * Sends a request to reset the user's password
 * @param {Object} data - Contains the email address
 * @returns {Promise} - The API response
 */
export const requestPasswordReset = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/auth/forgot-password?email=${encodeURIComponent(data.email)}`); 
    console.log(response);  
    return response.data;
  } catch (error) {
    console.error('Password reset request failed:', error);
    throw error;
  }
};

export const resetPassword = async (data) => {
    try {
        const response = await axios.post('/api/auth/reset-password', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
