import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../layouts/dashboard';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  
  // Helper function to safely get array data from translations
  const getTranslatedArray = (key) => {
    const result = t(key, { returnObjects: true });
    return Array.isArray(result) ? result : [];
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{t('privacyPolicy.title')}</h1>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.introduction.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.introduction.content')}</p>
          <p className="mb-4">{t('privacyPolicy.introduction.contactInfo')}</p>
          <p className="mb-4">{t('privacyPolicy.introduction.scope')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.consent.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.consent.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.dataCollection.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.dataCollection.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('privacyPolicy.dataCollection.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.dataUse.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.dataUse.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('privacyPolicy.dataUse.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.dataSecurity.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.dataSecurity.content')}</p>
          <p className="mb-4">{t('privacyPolicy.dataSecurity.retention')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.cookies.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.cookies.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.dataSharing.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.dataSharing.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('privacyPolicy.dataSharing.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.userRights.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.userRights.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('privacyPolicy.userRights.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
          <p className="mb-4">{t('privacyPolicy.userRights.exerciseRights')}</p>
          <p className="mb-4">{t('privacyPolicy.userRights.minorPolicy')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.policyChanges.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.policyChanges.content')}</p>
        </section>
        
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('privacyPolicy.contact.title')}</h2>
          <p className="mb-4">{t('privacyPolicy.contact.content')}</p>
          <p className="mb-4">{t('privacyPolicy.contact.email')}</p>
          <p className="mb-4">{t('privacyPolicy.contact.effectiveDate')}</p>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default PrivacyPolicy; 