import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import DashboardLayout from '../layouts/dashboard';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  const { t } = useTranslation();
  
  // Helper function to safely get array data from translations
  const getTranslatedArray = (key) => {
    const result = t(key, { returnObjects: true });
    return Array.isArray(result) ? result : [];
  };

  return (
    <DashboardLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{t('termsOfService.title')}</h1>
        
        <section className="mb-6">
          <p className="mb-4">{t('termsOfService.introduction')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.generalConditions.title')}</h2>
          <p className="mb-4">{t('termsOfService.generalConditions.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.content.title')}</h2>
          <p className="mb-4">{t('termsOfService.content.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.modifications.title')}</h2>
          <p className="mb-4">{t('termsOfService.modifications.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.productsServices.title')}</h2>
          <p className="mb-4">{t('termsOfService.productsServices.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.informationAccuracy.title')}</h2>
          <p className="mb-4">{t('termsOfService.informationAccuracy.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.thirdPartyLinks.title')}</h2>
          <p className="mb-4">{t('termsOfService.thirdPartyLinks.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.personalInfo.title')}</h2>
          <p className="mb-4">
           
             {t('termsOfService.personalInfo.content')}
             <Link to="/privacy-policy"><span className="text-ta--yellow px-[5px]">{t('termsOfService.personalInfo.link')}</span></Link>
         </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.prohibitedUses.title')}</h2>
          <p className="mb-4">{t('termsOfService.prohibitedUses.content')}</p>

        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.disclaimer.title')}</h2>
          <p className="mb-4">{t('termsOfService.disclaimer.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('termsOfService.disclaimer.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.indemnification.title')}</h2>
          <p className="mb-4">{t('termsOfService.indemnification.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('termsOfService.indemnification.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.cancellation.title')}</h2>
          <p className="mb-4">{t('termsOfService.cancellation.content')}</p>
          <ul className="list-disc pl-6 mb-4">
            {getTranslatedArray('termsOfService.cancellation.items').map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.entireAgreement.title')}</h2>
          <p className="mb-4">{t('termsOfService.entireAgreement.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.governingLaw.title')}</h2>
          <p className="mb-4">{t('termsOfService.governingLaw.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.severability.title')}</h2>
          <p className="mb-4">{t('termsOfService.severability.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.modificationsToTerms.title')}</h2>
          <p className="mb-4">{t('termsOfService.modificationsToTerms.content')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-3">{t('termsOfService.contact.title')}</h2>
          <p className="mb-4">{t('termsOfService.contact.content')}</p>
          <p className="mb-4">{t('termsOfService.contact.email')}</p>
        </section>
      </div>
    </DashboardLayout>
  );
};

export default TermsOfService; 