import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../services/axiosConfig';
import { Email, Send } from '@mui/icons-material';

const ContactUs = () => {
    const { t } = useTranslation();
    
    // Add form state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    // Handle input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axiosInstance.post('/api/v1/contact', formData);
            if (response?.data?.statusCode === 201) {
                toast.success(t('contact.successMessage'));
                // Reset form
                setFormData({ name: '', email: '', message: '' });
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.log(error);
            toast.error(t('contact.errorMessage'));
        }
    };

    return (
        <section
            id="contact-us"
            className="py-8 md:py-12 shadow-ta--bs rounded-3xl"
            style={{
                background: 'linear-gradient(135deg, rgba(0, 191, 255, 0.6))',
            }}
        >
            <div className="container mx-auto px-4 sm:px-8 md:px-16 lg:px-24 xl:px-32">
                <h2 className="text-2xl md:text-3xl font-bold mb-4 text-ta--headingBlue text-center md:text-left">
                    {t('contact.title')}
                </h2>
                
                <div className="flex flex-col md:flex-row md:items-start md:gap-8">
                    <div className="md:w-2/3">
                        <p className="mb-4 text-ta--textGrey">{t('contact.description')}</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-ta--headingBlue mb-2" htmlFor="name">
                                    {t('contact.nameLabel')}
                                </label>
                                <input
                                    className="w-full px-3 py-2 text-ta--darkBlue bg-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    placeholder={t('contact.namePlaceholder')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-ta--headingBlue mb-2" htmlFor="email">
                                    {t('contact.emailLabel')}
                                </label>
                                <input
                                    className="w-full px-3 py-2 text-ta--darkBlue bg-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    placeholder={t('contact.emailPlaceholder')}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-ta--headingBlue mb-2" htmlFor="message">
                                    {t('contact.messageLabel')}
                                </label>
                                <textarea
                                    className="w-full px-3 py-2 text-ta--darkBlue bg-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    id="message"
                                    name="message"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    placeholder={t('contact.messagePlaceholder')}
                                ></textarea>
                            </div>
                            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
                                <button
                                    className="bg-ta--darkBlue text-white px-6 py-2 rounded-full transition-all duration-200 hover:bg-ta--darkerBlue shadow-lg flex items-center gap-2"
                                    type="submit"
                                >
                                    <Send fontSize="small" />
                                    {t('contact.sendButton')}
                                </button>
                                
                                <div className="text-ta--darkBlue font-medium flex items-center gap-2">
                                    <Email className="text-ta--darkBlue" />
                                    <a href="mailto:support@auralid.com" className="hover:underline">
                                        support@auralid.com
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div className="hidden md:block md:w-1/3 bg-white bg-opacity-20 p-6 rounded-lg shadow-md mt-8 md:mt-0">
                        <h3 className="text-xl font-semibold text-ta--darkBlue mb-4">
                            {t('contact.directContact')}
                        </h3>
                        <div className="flex items-start gap-3 mb-4">
                            <Email className="text-ta--darkBlue mt-1" />
                            <div>
                                <p className="font-semibold text-ta--darkBlue">{t('contact.emailUsAt')}</p>
                                <a 
                                    href="mailto:support@auralid.com" 
                                    className="text-ta--darkBlue hover:underline"
                                >
                                    support@auralid.com
                                </a>
                                <p className="text-sm text-ta--textGrey mt-1">
                                    {t('contact.quickResponse')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
