import { useTranslation } from 'react-i18next';

const Tools = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-white mb-12">
                <div className="container mx-auto p-5">
                    {/* Section 1 */}
                    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                        <div className="w-full md:w-1/2 p-5">
                            <p className="text-ta--headingBlue font-bold text-2xl md:text-4xl mb-5 md:mb-8">
                                {t('landingpage.tools.section1.title')}
                            </p>
                            <p className="text-ta--headingBlue font-bold text-lg md:text-2xl mb-5 md:mb-8">
                                {t('landingpage.tools.section1.description')}
                            </p>
                            <ul className="text-ta--textGrey text-sm md:text-lg list-disc pl-5">
                                <li>{t('landingpage.tools.section1.feature1')}</li>
                                <li>{t('landingpage.tools.section1.feature2')}</li>
                                <li>{t('landingpage.tools.section1.feature3')}</li>
                            </ul>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="relative h-[97%] rounded-3xl">
                            <img src="upload-tools.png" alt="" />
                            </div>
                        </div>
                    </div>


                    {/* Section 2 */}
                    <div className="flex flex-col md:flex-row items-center justify-between gap-8 mt-8">
                        <div className="w-full md:w-1/2 relative">
                            <img className="absolute max-w-[250px] md:max-w-[300px] lg:max-w-[400px] h-auto top-[-50px] md:top-[-100px] left-0 md:left-[-50px] lg:left-[-100px]" src="bg-yellow.png" alt="" />
                            <img className="relative" src="tools-2.png" alt="" />
                        </div>
                        <div className="w-full md:w-1/2 p-5">
                            <p className="text-ta--headingBlue font-bold text-lg md:text-2xl mb-5 md:mb-8">
                                {t('landingpage.tools.section2.title')}
                            </p>
                            <p className="text-ta--textGrey text-sm md:text-lg mb-5 md:mb-8">
                                {t('landingpage.tools.section2.description')}
                            </p>
                            <ul className="text-ta--textGrey text-sm md:text-lg list-disc pl-5">
                                <li>{t('landingpage.tools.section2.feature1')}</li>
                                <li>{t('landingpage.tools.section2.feature2')}</li>
                                <li>{t('landingpage.tools.section2.feature3')}</li>
                                <li>{t('landingpage.tools.section2.feature4')}</li>
                            </ul>
                        </div>
                    </div>

                    {/* Section 3 */}
                    <div className="flex flex-col md:flex-row items-center justify-between gap-8 mt-8">
                        <div className="w-full md:w-1/2 p-5">
                            <p className="text-ta--headingBlue font-bold text-lg md:text-2xl mb-5 md:mb-8">
                                {t('landingpage.tools.section3.title')}
                            </p>
                            <p className="text-ta--textGrey text-sm md:text-lg">
                                {t('landingpage.tools.section3.description')}
                            </p>
                        </div>
                        <div className="w-full md:w-1/2 relative">
                            <img className="absolute max-w-[200px] md:max-w-[250px] lg:max-w-[300px] xl:max-w-[400px] h-auto top-[-30px] md:top-[-80px] right-0 md:right-[20px] lg:right-[-20px] xl:right-[-70px]" src="bg-yellow.png" alt="" />
                            <img className="relative" src="tools-3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tools;
