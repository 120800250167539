import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../layouts/dashboard';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const { t } = useTranslation();
  
  const defaultFaqs = [
    {
      question: "¿Necesito tarjeta de crédito para empezar?",
      answer: "No es necesaria. Puede registrarse siempre de forma gratuita sin necesidad de introducir ningún método de pago."
    },
    {
      question: "¿Existe periodo de prueba?",
      answer: "Puede usar el plan gratuito de forma permanente para probar el funcionamiento de la aplicación (limitado a 100 transacciones)."
    },
    {
      question: "Estoy empezando en Amazon y no tengo casi ventas, ¿debo suscribirme a un plan de pago?",
      answer: "Siempre que tengas menos de 100 transacciones para el periodo fiscal a analizar, puedes utilizarlo sin necesidad de pagar y los resultados serán 100% completos."
    },
    {
      question: "¿Qué significa 100 transacciones?",
      answer: "Con 'transacciones' nos referimos a pedidos, devoluciones, liquidaciones y movimientos de inventario."
    },
    {
      question: "¿Qué tipos de fichero acepta la plataforma?",
      answer: "Solamente acepta ficheros .csv y de un máximo de 100MB."
    },
    {
      question: "¿Dónde puedo descargar los ficheros fiscales necesarios para poder analizarlos?",
      answer: "Los ficheros se descargan en tu tienda de Amazon en la siguiente ruta: Menú --> Informes --> Logística de Amazon --> Informe de transacciones sujetas a IVA"
    },
    {
      question: "¿Sale error al intentar subir un archivo?",
      answer: "Puede ser porque haya caracteres extraños en el nombre. No modifique el nombre del archivo descargado de Amazon o si lo hace, evite caracteres susceptibles de error como tildes, símbolos …"
    },
    {
      question: "He subido el archivo de forma correcta pero no me aparece nada",
      answer: "Si al subir el archivo le ha salido una ventana indicando que se ha subido correctamente, espere uno o dos minutos y si no aparece en la lista de informes procesados, clique en actualizar/refrescar la página y debería aparecer allí."
    },
    {
      question: "Me aparece la página de análisis con un error diciendo que necesito suscripción, ¿qué debo hacer?",
      answer: "Desconéctese y vuelva a loguearse en la plataforma y el problema se habrá solucionado."
    },
    {
      question: "¿Qué tipos de informes genera la plataforma?",
      answer: "Los resultados podrá verlos online y descargárselos en dos tipos de archivos: Uno en pdf con el resumen listo para su gestor, y uno en formato Excel que contiene la misma información resumida que el pdf pero además posee una lista de todas las transacciones separadas por tipo fiscal y por país."
    },
    {
      question: "No sé qué plan he de contratar, ¿cómo puedo saberlo?",
      answer: "Para ello, use la versión gratuita en primer lugar y descárguese el resumen generado en pdf donde podrá ver el número de transacciones. Una vez lo tenga, puede contratar el plan que necesite."
    },
    {
      question: "¿La plataforma genera facturas de mi pago?",
      answer: "Sí, una vez suscrito puede descargarse las facturas correspondientes a sus pagos. Es muy importante que rellene todos los apartados durante el proceso de pago, incluyendo los datos fiscales (tanto si es trabajador por cuenta propia como empresa) para que aparezcan en la factura de forma correcta."
    },
    {
      question: "Soy autónomo pero al pagar no me deja poner mis datos fiscales",
      answer: "Seleccione la casilla de 'soy empresa' y allí podrá incluir su nombre y datos fiscales."
    }
  ];

  const faqArray = Array.isArray(t('faq.items', { returnObjects: true })) 
    ? t('faq.items', { returnObjects: true }) 
    : defaultFaqs;

  return (
    <DashboardLayout>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Box textAlign="center" mb={6}>
          <Typography variant="h3" component="h1" gutterBottom>
            {t('faq.title', 'Preguntas Frecuentes')}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" mb={6}>
            {t('faq.description', 'Encuentre respuestas a las preguntas más comunes sobre nuestra plataforma')}
          </Typography>
        </Box>

        <Box sx={{ bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3, p: 2 }}>
          {faqArray.map((faq, index) => (
            <Accordion key={index} sx={{ '&:not(:last-child)': { borderBottom: 1, borderColor: 'divider' } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6" component="h2">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography color="text.secondary">
                  {Array.isArray(faq.answer) ? (
                    faq.answer.map((paragraph, idx) => (
                      <Typography 
                        key={idx} 
                        color="text.secondary" 
                        paragraph={true}
                        sx={{ mb: idx < faq.answer.length - 1 ? 2 : 0 }}
                      >
                        {paragraph}
                      </Typography>
                    ))
                  ) : (
                    faq.answer
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default FAQ; 