import { Link } from "react-router-dom";
import Logo from "./logo";
import { FacebookSharp, Instagram, Email } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
  
    // SVG for X logo (former Twitter)
    const XLogo = () => (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="currentColor"
            className="text-white"
        >
            <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
        </svg>
    );
    
    return (
        <>
            <div className={`footer--div lg:pt-[200px] pt-[150px]`}>
                <div className={`container mx-auto p-5`}>
                    <div className={`flex items-center justify-between gap-4 flex-col md:flex-row`}>
                        <Logo />
                        <div className={`flex flex-col md:flex-row items-center justify-between gap-5`}>
                            <Link className={`text-white text-sm`} to={`/privacy-policy`}>{t('footer.privacyPolicy')}</Link>
                            <Link className={`text-white text-sm`} to={`/terms-and-service`}>{t('footer.termsOfService')}</Link>
                            <Link className={`text-white text-sm`} to={`/contact-us`}>{t('footer.contactUs')}</Link>
                            <Link className={`text-white text-sm`} to={`/faq`}>{t('nav.faq')}</Link>
                            <a 
                                className={`text-white text-sm flex items-center gap-1`} 
                                href="mailto:support@auralid.com"
                            >
                                <Email fontSize="small" />
                                support@auralid.com
                            </a>
                        </div>
                        <div className={`flex flex-row items-center justify-between gap-5`}>
                            {/* <Link className={`text-white text-sm`} to={`/facebook`} target={`_blank`}>
                                <FacebookSharp />
                            </Link>
                            <Link className={`text-white text-sm`} to={`https://x.com`} target={`_blank`}>
                                <XLogo />
                            </Link>
                            <Link className={`text-white text-sm`} to={`/instagram`} target={`_blank`}>
                                <Instagram />
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;