import { Link } from "react-router-dom";

const Logo = (props) => {
    return (
        <>
            <Link to={`/`}>
                <img src={`logo-new.png`} alt={`logo`} className="w-32 h-22" />   
            </Link>
        </>
    );
}

export default Logo;