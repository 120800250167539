import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../services/axiosConfig';
import { toast } from 'react-toastify';

const BillingPlan = ({ formData3, handleChange3, handleChange3Billing, setClientSecret, payments }) => {
    const { t } = useTranslation();
    const [currency, setCurrency] = useState('EUR');
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);
    const [subscriptionData, setSubscriptionData] = useState(null);
    
    // Get price from localStorage instead of static value
    const userSubscription = JSON.parse(localStorage.getItem('userSubscription') || '{"price": "19"}');
    const basePrice = parseFloat(userSubscription.price);
    const yearlyDiscount = 0.10;

    const currencies = [
        { code: 'EUR', symbol: '€' },
    ];

    const plans = [
        {
            title: t('landingpage.plans.plans.starter.title'),
            subtitle: t('landingpage.plans.plans.starter.subtitle'),
            price: 19.9,
            planType: 'starter'
        },
        {
            title: t('landingpage.plans.plans.standard.title'),
            subtitle: t('landingpage.plans.plans.standard.subtitle'),
            price: 49.9,
            planType: 'standard'
        },
        {
            title: t('landingpage.plans.plans.pro.title'),
            subtitle: t('landingpage.plans.plans.pro.subtitle'),
            price: 99.9,
            planType: 'pro',
        }
    ];

    const calculateNextBillingDate = (billingMode) => {
        const today = new Date();
        if (billingMode === 'Monthly') {
            return new Date(today.setMonth(today.getMonth() + 1)).toISOString().split('T')[0];
        } else if (billingMode === 'Yearly') {
            return new Date(today.setFullYear(today.getFullYear() + 1)).toISOString().split('T')[0];
        }
        return today.toISOString().split('T')[0];
    };

    const handleBillingSelection = (mode, selectedPlan) => {
        const amount = selectedPlan.price;
        const nextBillingDate = calculateNextBillingDate(mode);
        
        handleChange3Billing({
            mode,
            amount,
            planType: selectedPlan.planType,
            nextBillingDate
        });
    };

    // ----------------------------
    // UPDATED handlePayment method:
    // ----------------------------
    const handlePayment = async () => {
        try {
            setIsPaymentLoading(true);

            // 1. Identify the plan the user chose.
            const selectedPlan = plans.find(plan => plan.price === formData3.amount);

            // 2. Store plan data in localStorage (as before).
            const planNameMap = {
                [t('landingpage.plans.plans.starter.title')]: 'Basic',
                [t('landingpage.plans.plans.standard.title')]: 'Standard',
                [t('landingpage.plans.plans.pro.title')]: 'Pro',
                [t('landingpage.plans.plans.free.title')]: 'Free',
                [t('landingpage.plans.plans.enterprise.title')]: 'Enterprise'
            };
            const englishPlanName = planNameMap[selectedPlan.title] || selectedPlan.planType;
            const subscriptionData = {
                price: selectedPlan.price,
                planType: selectedPlan.planType,
                plan: englishPlanName
            };
            localStorage.setItem('userSubscription', JSON.stringify(subscriptionData));

            // 3. Create a dynamic description
            const expiryDate = subscriptionData.endDate ? new Date(subscriptionData.endDate).toLocaleDateString() : 'N/A';
            const description = `Plan: ${englishPlanName}, Price: ${selectedPlan.price} ${currency.toUpperCase()}, Expires on: ${expiryDate}`;

            // 4. Call your new create-checkout-session endpoint with Stripe checkout values.
            const requestBody = {
                amount: Math.round(selectedPlan.price * 100), // In cents
                currency: currency.toUpperCase(),            // e.g. "USD" or "EUR"
                description: description
            };
            const response = await axiosInstance.post(
                '/api/v1/payments/create-checkout-session',
                requestBody
            );

            // 5. Extract the sessionId from the response data.
            const sessionId = response.data.data.sessionId;

            // 6. Load your Stripe object. Replace with your actual publishable key!
            const stripe = await loadStripe(process.env.REACT_APP_STRIP_PUB_KEY);

            // 7. Redirect to the Stripe checkout page.
            if (stripe) {
                await stripe.redirectToCheckout({ sessionId });
            } else {
                toast.error('Failed to load Stripe.');
            }
        } catch (error) {
            console.error('Payment session creation failed:', error);
            toast.error(t('billing.notifications.paymentInitFailed'));
        } finally {
            setIsPaymentLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            setIsCancelLoading(true);
            const response = await axiosInstance.delete('/api/v1/subscriptions/unSubscribe');
            const { data } = await axiosInstance.get('/api/v1/subscriptions/userSubscription');
            setSubscriptionData(data.data);
            
            toast.success(t('billing.notifications.cancelSuccess'));

            if (!data.data.active) {
                handleChange3Billing({
                    mode: 'Monthly',
                    amount: basePrice,
                    nextBillingDate: calculateNextBillingDate('Monthly')
                });
            }
        } catch (error) {
            console.error('Cancellation failed:', error);
            toast.error(t('billing.notifications.cancelFailed'));
        } finally {
            setIsCancelLoading(false);
        }
    };

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                setIsLoading(true);
                const { data } = await axiosInstance.get('/api/v1/subscriptions/userSubscription');
                setSubscriptionData(data.data);
                
                if (data.data.active) {
                    const price = parseFloat(data.data.price) > 100 ? 
                    parseFloat(data.data.price) / 100 : 
                    parseFloat(data.data.price);
                    handleChange3Billing({
                        mode: data.data.planType,
                        amount: price,
                        nextBillingDate: data.data.endDate
                    });
                }
            } catch (error) {
                console.error('Failed to fetch subscription:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscription();
    }, [payments]);

    useEffect(() => {
        if (!formData3.planType && !subscriptionData?.active) {
            handleBillingSelection('Monthly', plans[0]);
        }
    }, []);

    const canSelectNewPlan = (subscription) => {
        return (
            !subscription ||
            !subscription.active ||
            (subscription.active && subscription.canceled) ||
            (subscription.active && subscription.planType === 'FREE')
        );
    };

    const canCancelSubscription = (subscription) => {
        return subscription && subscription.active && !subscription.canceled;
    };

    return (
        <div className="w-full mb-8">
            {isLoading ? (
                <div className="flex justify-center items-center py-10">
                    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                </div>
            ) : (
                <form className="shadow-lg px-5 py-6 md:py-10 rounded-xl">
                    {subscriptionData?.active && (
                        <div className="mb-6 flex flex-col sm:flex-row gap-2 sm:items-center">
                            <div
                                className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                                    subscriptionData.planType === 'FREE'
                                        ? 'bg-blue-100 text-blue-800'
                                        : subscriptionData.canceled
                                            ? 'bg-amber-100 text-amber-800'
                                            : 'bg-green-100 text-green-800'
                                }`}
                            >
                                <span
                                    className={`w-2 h-2 rounded-full mr-2 ${
                                        subscriptionData.planType === 'FREE'
                                            ? 'bg-blue-500'
                                            : subscriptionData.canceled
                                                ? 'bg-amber-500'
                                                : 'bg-green-500'
                                    }`}
                                ></span>
                                {subscriptionData.planType === 'FREE'
                                    ? t('billing.plan.status.freePlan')
                                    : subscriptionData.canceled
                                        ? t('billing.plan.status.canceledSubscription')
                                        : t('billing.plan.status.activeSubscription')}
                            </div>
                            {subscriptionData.endDate && (
                                <div className="text-sm text-gray-600">
                                  {
                                    t('billing.plan.status.expiryDate') + ' ' + new Date(subscriptionData.endDate).toLocaleDateString()
                                }
                                </div>
                            )}
                        </div>
                    )}

                    {canSelectNewPlan(subscriptionData) && (
                        <div className="w-full md:w-1/3 mb-4">
                            <label className="block mb-2">
                                {t('billing.plan.selectCurrency')}
                            </label>
                            <select
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                                className="w-full border rounded-md px-3 py-2 border-[#CCCCCC] bg-[#FAFAFA]"
                            >
                                {currencies?.map((curr) => (
                                    <option key={curr.code} value={curr.code}>
                                        {curr.code} ({curr.symbol})
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className="flex flex-col md:flex-row items-start justify-between gap-6 md:gap-10 lg:gap-16">
                        <div className="w-full md:w-1/2">
                            <p className="font-medium text-lg md:text-xl mb-8">
                                {t('billing.plan.title')}
                            </p>
                            <div className="grid grid-cols-1 gap-5">
                                {plans.map((plan) => {
                                    const isCurrentlySelected =
                                        formData3.amount === plan.price &&
                                        (!subscriptionData?.active ||
                                            (subscriptionData?.active && subscriptionData?.canceled));
                                    const isActivePlan =
                                        subscriptionData?.active &&
                                        subscriptionData?.plan === plan.title;
                                    
                                    return (
                                        <div
                                            key={plan.planType}
                                            onClick={() =>
                                                canSelectNewPlan(subscriptionData) &&
                                                handleBillingSelection('Monthly', plan)
                                            }
                                            className={`${
                                                isActivePlan
                                                    ? 'bg-[#F0FFF4] border-[#48BB78] shadow-[0_0_10px_rgba(72,187,120,0.2)]'
                                                    : isCurrentlySelected
                                                        ? 'bg-[#F0FFF4] border-[#48BB78] shadow-[0_0_10px_rgba(72,187,120,0.2)] active:bg-[#E6FFE6]'
                                                        : 'border-[#E6E6E6] active:bg-[#F0FFF4]'
                                            } ${
                                                !canSelectNewPlan(subscriptionData)
                                                    ? 'opacity-60 cursor-not-allowed'
                                                    : 'cursor-pointer'
                                            } transition-all border p-5 rounded-xl relative`}
                                        >
                                            {isActivePlan && (
                                                <div
                                                    className={`absolute -top-2 -right-2 px-2 py-1 rounded-md text-xs font-medium ${
                                                        subscriptionData.canceled
                                                            ? 'bg-amber-100 text-amber-800 border border-amber-300'
                                                            : 'bg-green-100 text-green-800 border border-green-300'
                                                    }`}
                                                >
                                                    {subscriptionData.endDate
                                                        ?
                                                          t('billing.plan.status.currentUntil') + ' ' + new Date(subscriptionData.endDate).toLocaleDateString()
                                                        : console.log('End date is not available:', subscriptionData.endDate)}
                                                </div>
                                            )}
                                            <div className="flex justify-between items-center">
                                                <div>
                                                    <p className="font-semibold text-black text-lg mb-1">
                                                        {plan.title}
                                                    </p>
                                                    <p className="text-[#4F4D55] text-sm">
                                                        {plan.subtitle}
                                                    </p>
                                                </div>
                                                <p className="text-lg font-bold">
                                                    {plan.price.toString().replace('.', ',')}{' '}
                                                    {
                                                        currencies.find(
                                                            (c) => c.code === currency
                                                        )?.symbol
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                                <div>
                                    <label className="block mb-1">
                                        {t('billing.plan.form.price')}{' '}
                                        <span className="text-red">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        name="price"
                                        value={formData3?.amount ? formData3.amount.toString().replace('.', ',') : '0'}
                                        disabled
                                        className="w-full border rounded-md px-3 py-2 border-[#CCCCCC] bg-[#F5F5F5] cursor-not-allowed"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-1">
                                        {t('billing.plan.form.nextBillingDate')}
                                    </label>
                                    <input
                                        type="date"
                                        name="nextBillingDate"
                                        value={
                                            formData3.nextBillingDate ||
                                            calculateNextBillingDate(formData3?.mode || 'Monthly')
                                        }
                                        disabled
                                        className="w-full border rounded-md px-3 py-2 border-[#CCCCCC] bg-[#F5F5F5] cursor-not-allowed"
                                    />
                                </div>
                                {/* <div className="col-span-1 md:col-span-2">
                                    <label className="block mb-1">
                                        {t('billing.plan.form.email')}
                                    </label>
                                </div> */}
                            </div>

                            <div className="mt-5 flex gap-4">
                                {/* Save / Confirm Payment Button */}
                                <button
                                    type="button"
                                    onClick={handlePayment}
                                    className={`w-full px-4 py-3 text-base md:text-lg text-white transition-all shadow rounded-lg flex items-center justify-center
                                        ${
                                            !canSelectNewPlan(subscriptionData)
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : 'bg-ta--blue hover:bg-ta--headingBlue'
                                        }`}
                                    disabled={isPaymentLoading || !canSelectNewPlan(subscriptionData)}
                                >
                                    {isPaymentLoading ? (
                                        <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                                    ) : (
                                        t('billing.plan.form.saveButton')
                                    )}
                                </button>

                                {/* Cancel Subscription Button */}
                                <button
                                    type="button"
                                    onClick={handleCancelSubscription}
                                    className={`w-1/3 px-3 py-2 text-sm text-gray-600 transition-all rounded-lg flex items-center justify-center
                                        ${
                                            !canCancelSubscription(subscriptionData)
                                                ? 'bg-gray-100 cursor-not-allowed'
                                                : 'bg-gray-200 hover:bg-gray-300 border border-gray-300'
                                        }`}
                                    disabled={isCancelLoading || !canCancelSubscription(subscriptionData)}
                                >
                                    {isCancelLoading ? (
                                        <div className="w-4 h-4 border-2 border-gray-400 border-t-transparent rounded-full animate-spin"></div>
                                    ) : (
                                        t('billing.plan.form.cancelButton')
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default BillingPlan;
