import Menu from "../components/menu";
import Footer from '../components/footer';

const DashboardLayout = (props) => {
    return (
        <>
            <Menu type={`dashboard`} />
            <div className={`min-h-[600px]`}>
                {props.children}
            </div>
            <Footer />
        </>
    );
}

export default DashboardLayout;