import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "./logo";
import Drawer from '@mui/material/Drawer';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import { GB, DE, ES ,FR,IT} from 'country-flag-icons/react/3x2';
import { logout } from '../services/auth';

const MenuBar = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Hook to handle translations
    // Get the current location using window.location.pathname
    const currentPath = window.location.pathname;
    
    // Define common routes that should use the common menu
    const commonRoutes = ['/', '/privacy-policy', '/terms-and-service', '/faq'];
    
    // Override the type prop if we're on a common route
    let type = commonRoutes.includes(currentPath) ? "common" : (props.type ?? "common");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [user, setUser] = useState({})
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [languageDropdown, setLanguageDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const toggleDropdown = () => setIsOpenDropdown(!isOpenDropdown);
    const toggleLanguageDropdown = () => setLanguageDropdown(!languageDropdown);
    
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpenDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let token = localStorage.getItem("token");
        let username = localStorage.getItem("username");
        let loginStatus = localStorage.getItem("isLoggedIn");
        
        if (loginStatus === 'true' && token) {
            setIsLoggedIn(true);
            setUser({ name: username });
        }
    }, []);

    // Add this useEffect to load the saved language preference
    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        setSelectedLanguage(savedLanguage);
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen);
    };

    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
        localStorage.setItem('language', lang); // Save language preference
        setLanguageDropdown(false);
    };
    const handleLogout = () => {
        logout();
        setIsLoggedIn(false);
        setUser({});
        setOpenDrawer(false);
        navigate('/signin');
    };

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    };

    const menuItemClass = `
        text-white 
        transition-all 
        hover:text-ta--blue 
        hover:bg-white 
        text-center 
        text-sm 
        md:text-base 
        px-[1rem] 
        md:px-[2rem] 
        py-2 
        rounded-full 
        font-medium
        tracking-wide
        shadow-sm
        hover:shadow-md
        transform
        hover:-translate-y-0.5
        duration-200
    `;

    const AuthLinks = ({ display }) => (
        <>
            {
                isLoggedIn
                    ? <div className={`flex ${display && display === "mobile" ? "flex-col mb-2" : "hidden md:flex"} items-center justify-between gap-2`}>
                       <span className="text-white">
                        {t('nav.greeting')}
                        {capitalizeFirstLetter(user?.name)}
                        </span>
                        <button 
                            onClick={handleLogout}
                            className={menuItemClass}
                        >
                            {t('nav.logout')}
                        </button>
                    </div>
                    : <div className={`flex ${display && display === "mobile" ? "flex-col mb-2" : "hidden md:flex"} items-center justify-between gap-2`}>
                        <Link className={menuItemClass} to={`/signin`}>
                            {t('auth.signin')}
                        </Link>
                        <Link className={`${menuItemClass} border border-white`} to={`/signup`}>
                            {t('auth.signup')}
                        </Link>
                    </div>
            }
        </>
    );

    const CommonLinks = ({ display }) => {
        // Check if we're on privacy, terms, or faq pages
        const isOnStaticPage = ['/privacy-policy', '/terms-and-service', '/faq'].includes(window.location.pathname);

        return (
            <div className={`flex ${display && display === "mobile" ? "flex-col mb-2" : "hidden md:flex"} items-center justify-between gap-2`}>
                {isOnStaticPage ? (
                    // If on static pages, use Link to navigate to home with hash
                    <Link className={menuItemClass} to="/#tools">
                        {t('nav.tools')}
                    </Link>
                ) : (
                    // If on home page, use anchor tag
                    <a className={menuItemClass} href="#tools">
                        {t('nav.tools')}
                    </a>
                )}
                
                {isOnStaticPage ? (
                    <Link className={menuItemClass} to="/#pricing">
                        {t('nav.pricing')}
                    </Link>
                ) : (
                    <a className={menuItemClass} href="#pricing">
                        {t('nav.pricing')}
                    </a>
                )}
                
                <Link className={menuItemClass} to="/faq">
                    {t('nav.faq')}
                </Link>
            </div>
        );
    };

    const DashboardLinks = ({ display }) => (
        <div className={`flex ${display && display === "mobile" ? "flex-col mb-2" : "hidden md:flex"} items-center justify-between gap-2`}>
            <Link className={menuItemClass} to={`/graphics`}>
                {t('dashboard.graphics')}
            </Link>
            <Link className={menuItemClass} to={`/information`}>
                {t('dashboard.information')}
            </Link>
            <Link className={menuItemClass} to="/faq">
                {t('nav.faq')}
            </Link>
            <div className={`relative`} ref={dropdownRef}>
                <button className={menuItemClass} onClick={toggleDropdown}>
                    {t('dashboard.accountBilling')} <ArrowDropDownIcon />
                </button>
                {isOpenDropdown && (
                    <div className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-10">
                        <MenuItem>
                            <Link 
                                className={`w-full text-ta--blue transition-all hover:text-ta--headingBlue text-base font-medium`} 
                                to={`/account`}
                            >
                                {t('dashboard.account')}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link 
                                className={`w-full text-ta--blue transition-all hover:text-ta--headingBlue text-base font-medium`} 
                                to={`/billing`}
                            >
                                {t('dashboard.billing')}
                            </Link>
                        </MenuItem>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <>
            <div className={`w-full bg-ta--blue`}>
                <div className={`container mx-auto p-3 md:p-5 flex items-center justify-between gap-4`}>
                    <div className="flex-shrink-0 w-[150px]">
                        <Logo />
                    </div>

                    {/* Desktop Menu (>=1200px) */}
                    <div className="hidden xl:flex items-center gap-4">
                        {type === "auth" && <AuthLinks display="desktop" />}
                        {type === "common" && (
                            <>
                                {isLoggedIn ? (
                                    <>
                                        <DashboardLinks display="desktop" />
                                        <AuthLinks display="desktop" />
                                    </>
                                ) : (
                                    <>
                                        <CommonLinks display="desktop" />
                                        <AuthLinks display="desktop" />
                                    </>
                                )}
                            </>
                        )}
                        {type === "dashboard" && (
                            <>
                                <DashboardLinks display="desktop" />
                                <AuthLinks display="desktop" />
                            </>
                        )}
                    </div>

                    {/* Mobile Menu Button (<1200px) */}
                    <div className="xl:hidden">
                        <Button onClick={toggleDrawer(true)}>
                            <MenuRoundedIcon className="text-white" />
                        </Button>
                    </div>

                    <div className="relative">
                        <Button 
                            style={{ color: 'white' }}
                            className="flex items-center hover:bg-ta--lightBlue px-4 py-2 rounded-full transition-all duration-200" 
                            onClick={toggleLanguageDropdown}
                        >
                            {selectedLanguage === 'en' && <GB className="w-6 h-4 mr-2" />}
                            {selectedLanguage === 'de' && <DE className="w-6 h-4 mr-2" />}
                            {selectedLanguage === 'es' && <ES className="w-6 h-4 mr-2" />}
                            {selectedLanguage === 'fr' && <FR className="w-6 h-4 mr-2" />}
                            {selectedLanguage === 'it' && <IT className="w-6 h-4 mr-2" />}
                            {t(`nav.${selectedLanguage}`)}
                        </Button>
                        {languageDropdown && (
                            <div className="absolute top-full mt-2 right-0 bg-white rounded-md shadow-lg z-10">
                                <MenuItem onClick={() => changeLanguage('en')} className="hover:bg-gray-50">
                                    <GB className="w-6 h-4 mr-2" /> English
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage('de')} className="hover:bg-gray-50">
                                    <DE className="w-6 h-4 mr-2" /> Deutsch
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage('es')} className="hover:bg-gray-50">
                                    <ES className="w-6 h-4 mr-2" /> Español
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage('fr')} className="hover:bg-gray-50">
                                    <FR className="w-6 h-4 mr-2" /> Français
                                </MenuItem>
                                <MenuItem onClick={() => changeLanguage('it')} className="hover:bg-gray-50">
                                    <IT className="w-6 h-4 mr-2" /> Italiano
                                </MenuItem>
                            </div>
                        )}
                    </div>
                </div>

                {/* Mobile Drawer (<1200px) */}
                <Drawer 
                    open={openDrawer} 
                    onClose={toggleDrawer(false)}
                    anchor="right"
                    sx={{
                        display: { xl: 'none' },
                        '& .MuiDrawer-paper': {
                            backgroundColor: '#155896',
                            color: '#ffffff',
                            width: '100%',
                            maxWidth: '350px',
                            padding: '2rem 1.5rem'
                        }
                    }}
                >
                    <div className="flex flex-col h-full">
                        <div className="flex justify-between items-center mb-8">
                            <h2 className="text-xl font-semibold text-white">Menu</h2>
                            <Button 
                                onClick={toggleDrawer(false)}
                                className="text-white hover:bg-ta--lightBlue p-2 rounded-full"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </Button>
                        </div>

                        <div className="menu-items space-y-6">
                            {type === "auth" && <AuthLinks display="mobile" />}
                            {type === "common" && (
                                <>
                                    {isLoggedIn ? (
                                        <>
                                            <DashboardLinks display="mobile" />
                                            <AuthLinks display="mobile" />
                                        </>
                                    ) : (
                                        <>
                                            <CommonLinks display="mobile" />
                                            <AuthLinks display="mobile" />
                                        </>
                                    )}
                                </>
                            )}
                            {type === "dashboard" && (
                                <>
                                    <DashboardLinks display="mobile" />
                                    <AuthLinks display="mobile" />
                                </>
                            )}
                        </div>
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default MenuBar;
