import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Star } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const Testimonials = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Track window width for responsive adjustments
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    // Only modify settings for mobile
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: "slick-dots custom-dots",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: windowWidth < 768 ? "none" : "block", right: "-50px", width: "50px", height: "50px" }}
                onClick={onClick}
            />
        );
    }
      
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: windowWidth < 768 ? "none" : "block", left: "-60px", width: "50px", height: "50px" }}
                onClick={onClick}
            />
        );
    }
    
    const testimonials = [
        {
            name: 'Javier Moreno',
            review: t('testimonials.review1')
        },
        {
            name: 'Emma Meyer',
            review: t('testimonials.review2')
        },
        {
            name: 'Ana Caballero',
            review: t('testimonials.review3')
        }
    ];
    
    return (
        <>
            <div className={`bg-ta--blue`}>
                <div className={`container mx-auto p-4 md:p-8 mb-8 rounded-3xl`}>
                    <p className={`text-white font-lg font-semibold uppercase mb-4 text-2xl lg:text-4xl`}>
                        {t('testimonials.title')}
                    </p>
                    
                    {/* Flex column on mobile, row on desktop */}
                    <div className={`flex flex-col md:flex-row items-center md:items-start justify-between gap-6`}>
                        <p className={`text-white font-bold text-xl md:text-xl lg:text-xl mb-6 md:mb-0 text-center md:text-left`}>
                            {t('testimonials.description')}
                        </p>
                        
                        {/* Full width on mobile, half width on desktop */}
                        <div className={`w-full md:w-1/2`}>
                            <Slider {...settings}>
                                {/* First testimonial */}
                                <div className={`min-h-[250px] flex items-center justify-start p-5 bg-white shadow rounded-3xl`}>
                                    <div className={`flex flex-col sm:flex-row items-center sm:items-start justify-start gap-4`}>
                                        <div className="avatar-circle mb-3 sm:mb-0">
                                            {testimonials[0].name.charAt(0)}
                                        </div>
                                        <div>
                                            <p className={`font-bold text-2xl md:text-3xl mb-3 text-center sm:text-left`}>
                                                {testimonials[0].name}
                                            </p>
                                            <p className={`text-base md:text-lg mb-3 text-center sm:text-left`}>
                                                {testimonials[0].review}
                                            </p>
                                            <div className="flex justify-center sm:justify-start">
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Second testimonial */}
                                <div className={`min-h-[250px] flex items-center justify-start p-5 bg-white shadow rounded-3xl`}>
                                    <div className={`flex flex-col sm:flex-row items-center sm:items-start justify-start gap-4`}>
                                        <div className="avatar-circle mb-3 sm:mb-0">
                                            {testimonials[1].name.charAt(0)}
                                        </div>
                                        <div>
                                            <p className={`font-bold text-2xl md:text-3xl mb-3 text-center sm:text-left`}>
                                                {testimonials[1].name}
                                            </p>
                                            <p className={`text-base md:text-lg mb-3 text-center sm:text-left`}>
                                                {testimonials[1].review}
                                            </p>
                                            <div className="flex justify-center sm:justify-start">
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Third testimonial */}
                                <div className={`min-h-[250px] flex items-center justify-start p-5 bg-white shadow rounded-3xl`}>
                                    <div className={`flex flex-col sm:flex-row items-center sm:items-start justify-start gap-4`}>
                                        <div className="avatar-circle mb-3 sm:mb-0">
                                            {testimonials[2].name.charAt(0)}
                                        </div>
                                        <div>
                                            <p className={`font-bold text-2xl md:text-3xl mb-3 text-center sm:text-left`}>
                                                {testimonials[2].name}
                                            </p>
                                            <p className={`text-base md:text-lg mb-3 text-center sm:text-left`}>
                                                {testimonials[2].review}
                                            </p>
                                            <div className="flex justify-center sm:justify-start">
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                                <Star className={`text-[#D3C033]`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            
            <style jsx>{`
                .avatar-circle {
                    background-color: #FCD34D; /* Tailwind yellow-400 */
                    width: 5rem;
                    height: 5rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;
                    font-weight: bold;
                }
                
                @media (min-width: 640px) {
                    .avatar-circle {
                        width: 10rem;
                        height: 4rem;
                    }
                }
                
                :global(.custom-dots li) {
                    margin: 0 8px;
                }
                
                :global(.custom-dots li button:before) {
                    font-size: 16px;
                }
                
                :global(.slick-prev:before), :global(.slick-next:before) {
                    font-size: 40px;
                }
                
                .slick-prev{
                    left: -60px;
                }
                
                .slick-next{
                    right: -60px;
                }
                
                .slick-prev:before, .slick-next:before {
                    font-family: 'slick';
                    font-size: 60px;
                    line-height: 1;
                    opacity: .75;
                    color: white;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                
                .slick-dots li button:before {
                    font-size: 24px;
                    color: #fff;
                }
                
                /* Fix slider overflow issues on mobile */
                @media (max-width: 640px) {
                    :global(.slick-slider) {
                        width: 100%;
                        overflow: hidden;
                    }
                    
                    :global(.slick-list) {
                        overflow: visible !important;
                    }
                }
            `}</style>
        </>
    );
}

export default Testimonials;

