import { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/dashboard';
import axiosInstance from '../services/axiosConfig';
import Upload from '../components/upload';
import Download from '../components/download';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Information = () => {
    const { t } = useTranslation();
    const [mainTab, setMainTab] = useState('SUMMARY');
    const [categoryTab, setCategoryTab] = useState('UNION-OSS');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileType, setFileType] = useState('monthly');
    const [month, setMonth] = useState('1');
    const [quarter, setQuarter] = useState('Q1');
    const [year, setYear] = useState(new Date().getFullYear());
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [files, setFiles] = useState({ monthly: [], quarterly: [] });
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [downloadingFiles, setDownloadingFiles] = useState(new Set());
    const [categories, setCategories] = useState([]);

    const months = [
        { value: '1', label: 'January' },
        { value: '2', label: 'February' },
        { value: '3', label: 'March' },
        { value: '4', label: 'April' },
        { value: '5', label: 'May' },
        { value: '6', label: 'June' },
        { value: '7', label: 'July' },
        { value: '8', label: 'August' },
        { value: '9', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' }
    ];
    
    const quarters = [
        { value: 'Q1', label: 'First Quarter' },
        { value: 'Q2', label: 'Second Quarter' },
        { value: 'Q3', label: 'Third Quarter' },
        { value: 'Q4', label: 'Fourth Quarter' }
    ];

    const quarterMonths = {
        'Q1': '1',
        'Q2': '4',
        'Q3': '7',
        'Q4': '10'
    };

    const years = Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i);

    const processCountryData = (countries, selectedCategory = 'ALL') => {
        if (!Array.isArray(countries)) {
            console.error('Invalid data structure:', countries);
            return [];
        }

        // Get all unique categories
        const allCategories = new Set();
        countries?.forEach(country => {
            if (country.transactionCategories) {
                country.transactionCategories?.forEach(cat => {
                    allCategories.add(cat.category);
                });
            }
        });

        // Filter and process data based on selected category
        const processedData = countries.map(country => {
            if (!country.transactionCategories) {
                return {
                    country: country.country,
                    total: 0,
                    base: 0,
                    vat: 0,
                    currency: 'EUR'
                };
            }

            const categoryData = country.transactionCategories
                .filter(cat => selectedCategory === 'ALL' || cat.category === selectedCategory)
                .reduce((acc, cat) => {
                    // Calculate totals from transactions
                    let categoryTotal = 0;
                    let categoryBase = 0;
                    let categoryVat = 0;

                    if (cat.transactions && cat.transactions.TRANSACTION) {
                        cat.transactions.TRANSACTION?.forEach(transaction => {
                            categoryTotal += transaction.total || 0;
                            categoryBase += transaction.base || 0;
                        });
                    }

                    if (cat.transactions && cat.transactions.VAT) {
                        cat.transactions.VAT?.forEach(vat => {
                            categoryVat += vat.vat || 0;
                        });
                    }

                    return {
                        total: acc.total + categoryTotal,
                        base: acc.base + categoryBase,
                        vat: acc.vat + categoryVat
                    };
                }, { total: 0, base: 0, vat: 0 });

            // Add VAT details processing
            const vatDetails = country.transactionCategories
                ?.filter(cat => selectedCategory === 'ALL' || cat.category === selectedCategory)
                .flatMap(cat => cat.transactions?.VAT || []);

            // Add transaction details processing
            const transactionDetails = country.transactionCategories
                ?.filter(cat => selectedCategory === 'ALL' || cat.category === selectedCategory)
                .flatMap(cat => cat.transactions?.TRANSACTION || []);

            return {
                country: country.country,
                ...categoryData,
                currency: 'EUR',
                vatDetails: vatDetails,
                transactionDetails: transactionDetails
            };
        });

        // Filter out countries with zero total and return the processed data
        return processedData.filter(item => item.total !== 0);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const requestBody = {
                fileType,
                month: fileType === 'monthly' ? month : 1,
                quarter: fileType === 'quarterly' ? quarter : 'Q1',
                year,
            };
            const response = await axiosInstance.post('/api/v1/data/get-json', requestBody);
            
            // Check if response contains error
            if (response.data.error) {
                toast.error(response.data.error);
                setData([]);
                return;
            }

            // Check if data exists and is valid
            if (!response.data.data || !Array.isArray(response.data.data)) {
                toast.warning('No data available for the selected period');
                setData([]);
                return;
            }

            const processedData = processCountryData(response.data.data, categoryTab);
            setData(processedData);
        } catch (error) {
            setData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFiles = async () => {
        try {
            const response = await axiosInstance.get('/api/v1/data/user-files');
            setFiles(response.data);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const checkSubscriptionStatus = async () => {
        try {
            const response = await axiosInstance.get('/api/v1/subscriptions/userSubscription');
            setIsSubscriptionActive(response.data.data.active);
        } catch (error) {
            console.error('Error checking subscription:', error);
            setIsSubscriptionActive(false);
        } finally {
            setIsInitialLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        fetchFiles();
        checkSubscriptionStatus();
    }, [categoryTab, fileType, month, quarter, year]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.post('/api/v1/data/get-json', {
                    fileType,
                    month: fileType === 'monthly' ? month : 1,
                    quarter: fileType === 'quarterly' ? quarter : 'Q1',
                    year,
                });
                
                if (response.data.data) {
                    const allCategories = new Set();
                    response.data?.data?.forEach(country => {
                        if (country.transactionCategories) {
                            country.transactionCategories?.forEach(cat => {
                                allCategories.add(cat.category);
                            });
                        }
                    });
                    setCategories(Array.from(allCategories));
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [fileType, month, quarter, year]);

    const renderMainTabs = () => (
        <div className="container mx-auto p-5 border-b">
            {[
                { id: 'SUMMARY', label: t('information.mainTabs.summary') },
                { id: 'VAT DETAILED', label: t('information.mainTabs.vatDetailed') },
                { id: 'TRANSACTION DETAILED', label: t('information.mainTabs.transactionDetailed') }
            ].map((tab) => (
                <button
                    key={tab.id}
                    onClick={() => setMainTab(tab.id)}
                    className={`px-4 py-2 mr-4 ${
                        mainTab === tab.id 
                            ? 'font-bold border-b-2 border-blue-600' 
                            : 'text-gray-600'
                    }`}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );

    const renderCategoryTabs = () => (
        <div className="container mx-auto p-5 border-b">
            {categories.map(category => (
                <button
                    key={category}
                    onClick={() => setCategoryTab(category)}
                    className={`px-4 py-2 mr-4 ${
                        categoryTab === category 
                            ? 'font-bold border-b-2 border-blue-600' 
                            : 'text-gray-600'
                    }`}
                >
                    {category}
                </button>
            ))}
        </div>
    );

    const renderSummaryTable = () => (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <table className="min-w-full bg-white">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="text-left p-4">{t('information.table.country')}</th>
                        <th className="text-right p-4">{t('information.table.total')}</th>
                        <th className="text-right p-4">{t('information.table.base')}</th>
                        <th className="text-right p-4">{t('information.table.vat')}</th>
                        <th className="text-right p-4">{t('information.table.currency')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border-b">
                            <td className="p-4">{item.country}</td>
                            <td className="text-right p-4">
                                {item.total.toFixed(2)}
                            </td>
                            <td className="text-right p-4">
                                {item.base.toFixed(2)}
                            </td>
                            <td className="text-right p-4">
                                {item.vat.toFixed(2)}
                            </td>
                            <td className="text-right p-4">{item.currency}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const toggleRow = (countryId) => {
        const newExpandedRows = new Set(expandedRows);
        if (newExpandedRows.has(countryId)) {
            newExpandedRows.delete(countryId);
        } else {
            newExpandedRows.add(countryId);
        }
        setExpandedRows(newExpandedRows);
    };

    const getTransactionTypeStyle = (transactionType) => {
        const type = transactionType.toLowerCase();
        if (type.includes('sale')) {
            return 'bg-green-100 text-green-800';
        } else if (type.includes('refund')) {
            return 'bg-red-100 text-red-800';
        }
        return 'bg-gray-100 text-gray-800'; // default style for unknown types
    };

    const renderVatDetailedTable = () => (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <table className="min-w-full">
                <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                        <th className="text-left p-4 text-sm font-semibold text-gray-600"></th>
                        <th className="text-left p-4 text-sm font-semibold text-gray-600">{t('information.table.country')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.total')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.base')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.vat')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.currency')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <>
                            <tr 
                                key={index} 
                                className={`border-b transition-colors duration-200 
                                    ${expandedRows.has(index) ? 'bg-blue-50' : 'hover:bg-gray-50'} 
                                    cursor-pointer`}
                                onClick={() => toggleRow(index)}
                            >
                                <td className="p-4">
                                    <span className={`transition-transform duration-200 inline-block 
                                        ${expandedRows.has(index) ? 'transform rotate-90' : ''}`}>
                                        <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </span>
                                </td>
                                <td className="p-4 font-medium">{item.country}</td>
                                <td className="text-right p-4">{item.total.toFixed(2)}</td>
                                <td className="text-right p-4">{item.base.toFixed(2)}</td>
                                <td className="text-right p-4">{item.vat.toFixed(2)}</td>
                                <td className="text-right p-4">{item.currency}</td>
                            </tr>
                            {expandedRows.has(index) && item.vatDetails && (
                                <tr>
                                    <td colSpan="6" className="p-4 bg-gray-50">
                                        <div className="rounded-lg overflow-hidden border border-gray-200">
                                            <table className="min-w-full">
                                                <thead>
                                                    <tr className="bg-gray-100">
                                                        <th className="text-left p-3 text-sm font-semibold text-gray-600">VAT %</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">Total</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">Base</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">VAT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.vatDetails.map((vat, vatIndex) => (
                                                        <tr key={vatIndex} className="border-b hover:bg-white transition-colors duration-150">
                                                            <td className="p-3">{(vat.vat_percentage * 100).toFixed(0)}%</td>
                                                            <td className="text-right p-3">{vat.total.toFixed(2)}</td>
                                                            <td className="text-right p-3">{vat.base.toFixed(2)}</td>
                                                            <td className="text-right p-3">{vat.vat.toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const renderTransactionDetailedTable = () => (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <table className="min-w-full">
                <thead>
                    <tr className="bg-gray-50 border-b border-gray-200">
                        <th className="text-left p-4 text-sm font-semibold text-gray-600"></th>
                        <th className="text-left p-4 text-sm font-semibold text-gray-600">{t('information.table.country')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.total')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.base')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.vat')}</th>
                        <th className="text-right p-4 text-sm font-semibold text-gray-600">{t('information.table.currency')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <>
                            <tr 
                                key={index} 
                                className={`border-b transition-colors duration-200 
                                    ${expandedRows.has(index) ? 'bg-blue-50' : 'hover:bg-gray-50'} 
                                    cursor-pointer`}
                                onClick={() => toggleRow(index)}
                            >
                                <td className="p-4">
                                    <span className={`transition-transform duration-200 inline-block 
                                        ${expandedRows.has(index) ? 'transform rotate-90' : ''}`}>
                                        <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                        </svg>
                                    </span>
                                </td>
                                <td className="p-4 font-medium">{item.country}</td>
                                <td className="text-right p-4">{item.total.toFixed(2)}</td>
                                <td className="text-right p-4">{item.base.toFixed(2)}</td>
                                <td className="text-right p-4">{item.vat.toFixed(2)}</td>
                                <td className="text-right p-4">{item.currency}</td>
                            </tr>
                            {expandedRows.has(index) && item.transactionDetails && (
                                <tr>
                                    <td colSpan="6" className="p-4 bg-gray-50">
                                        <div className="rounded-lg overflow-hidden border border-gray-200">
                                            <table className="min-w-full">
                                                <thead>
                                                    <tr className="bg-gray-100">
                                                        <th className="text-left p-3 text-sm font-semibold text-gray-600">Type</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">Total</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">Base</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">VAT</th>
                                                        <th className="text-right p-3 text-sm font-semibold text-gray-600">Currency</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.transactionDetails.map((transaction, transactionIndex) => (
                                                        <tr key={transactionIndex} className="border-b hover:bg-white transition-colors duration-150">
                                                            <td className="p-3">
                                                                <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                                                                    getTransactionTypeStyle(transaction.transaction_type)
                                                                }`}>
                                                                    {transaction.transaction_type || 'N/A'}
                                                                </span>
                                                            </td>
                                                            <td className={`text-right p-3 ${
                                                                transaction.transaction_type.toLowerCase().includes('refund') 
                                                                    ? 'text-red-600' 
                                                                    : 'text-green-600'
                                                            }`}>
                                                                {transaction.total.toFixed(2)}
                                                            </td>
                                                            <td className={`text-right p-3 ${
                                                                transaction.transaction_type.toLowerCase().includes('refund') 
                                                                    ? 'text-red-600' 
                                                                    : 'text-green-600'
                                                            }`}>{transaction.base.toFixed(2)}</td>
                                                             <td className={`text-right p-3 ${
                                                                transaction.transaction_type.toLowerCase().includes('refund') 
                                                                    ? 'text-red-600' 
                                                                    : 'text-green-600'
                                                            }`}>{transaction.vat.toFixed(2)}</td>
                                                             <td className={`text-right p-3 ${
                                                                transaction.transaction_type.toLowerCase().includes('refund') 
                                                                    ? 'text-red-600' 
                                                                    : 'text-green-600'
                                                            }`}>{transaction.currency}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const getDateFromFilename = (filename) => {
        const parts = filename.split('/');
        if (parts[0].includes('-')) {
            const [month, year] = parts[0].split('-');
            return `${year}-${month.padStart(2, '0')}-01`;
        }
        return 'N/A';
    };

    const getSerialNumber = (index) => {
        return `SR${(index + 1).toString().padStart(3, '0')}`;
    };

    // Add the month mapping at the top with other constants
    const monthToEnglishMap = {
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December",
        // German months
        "Januar": "January",
        "Februar": "February",
        "März": "March",
        "Mai": "May",
        "Juni": "June",
        "Juli": "July",
        "Oktober": "October",
        "Dezember": "December",
        // Spanish months
        "Enero": "January",
        "Febrero": "February",
        "Marzo": "March",
        "Abril": "April",
        "Junio": "June",
        "Julio": "July",
        "Agosto": "August",
        "Septiembre": "September",
        "Octubre": "October",
        "Noviembre": "November",
        "Diciembre": "December",
        // French months
        "Janvier": "January",
        "Février": "February",
        "Mars": "March",
        "Avril": "April",
        "Mai": "May",
        "Juin": "June",
        "Juillet": "July",
        "Août": "August",
        "Septembre": "September",
        "Octobre": "October",
        "Novembre": "November",
        "Décembre": "December",
        // Italian months
        "Gennaio": "January",
        "Febbraio": "February",
        "Marzo": "March",
        "Aprile": "April",
        "Maggio": "May",
        "Giugno": "June",
        "Luglio": "July",
        "Agosto": "August",
        "Settembre": "September",
        "Ottobre": "October",
        "Novembre": "November",
        "Dicembre": "December"
    };

    // Update the monthMap to use numbers
    const monthMap = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };

    const handleDownload = async (fileType, filePath) => {
        try {
            setDownloadingFiles(prev => new Set([...prev, filePath]));
            
            // Extract month/quarter and year from the file path
            const parts = filePath.split('/');
            const [periodPart, year] = parts[0].split('-');
            
            // Convert numeric month to English month name
            const numberToMonthMap = {
                1: "January",
                2: "February",
                3: "March",
                4: "April",
                5: "May",
                6: "June",
                7: "July",
                8: "August",
                9: "September",
                10: "October",
                11: "November",
                12: "December"
            };

            // Check if periodPart is a number between 1-12
            const isMonth = !isNaN(periodPart) && parseInt(periodPart) >= 1 && parseInt(periodPart) <= 12;
            
            const requestData = {
                fileType: isMonth ? 'monthly' : 'quarterly',
                year: parseInt(year),
                fileExtension: "xlsx"
            };

            if (isMonth) {
                const monthNumber = parseInt(periodPart);
                requestData.month = monthNumber;

                // Get English month name for the filename
                const englishMonth = numberToMonthMap[monthNumber];
                // Get translated month name based on current language
                const translatedMonth = t(`information.months.${englishMonth}`);

                // Update the download filename to use the translated month
                parts[0] = `${translatedMonth}-${year}`;
            } else {
                requestData.quarter = periodPart;
                requestData.month = 1;
            }

            const response = await axiosInstance.post('/api/v1/data/downloadFile', requestData, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${parts[0]}_report_${year}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Error downloading file');
        } finally {
            setDownloadingFiles(prev => {
                const newSet = new Set(prev);
                newSet.delete(filePath);
                return newSet;
            });
        }
    };

    const renderFilesTable = () => (
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <table className="min-w-full bg-white">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="text-left p-4">{t('information.filesTable.srNo')}</th>
                        <th className="text-left p-4">{t('information.filesTable.fileName')}</th>
                        <th className="text-left p-4">{t('information.filesTable.type')}</th>
                        <th className="text-left p-4">{t('information.filesTable.date')}</th>
                        <th className="text-left p-4">{t('information.filesTable.actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        ...files.monthly.map(file => ({ file, type: t('information.fileTypes.monthly') })),
                        ...files.quarterly.map(file => ({ file, type: t('information.fileTypes.quarterly') }))
                    ].map((item, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50">
                            <td className="p-4">{getSerialNumber(index)}</td>
                            <td className="p-4">{item.file.split('/').pop()}</td>
                            <td className="p-4 capitalize">{item.type}</td>
                            <td className="p-4">{getDateFromFilename(item.file)}</td>
                            <td className="p-4">
                                <button 
                                    onClick={() => handleDownload(item.type.toLowerCase().includes('monthly') ? 'monthly' : 'quarterly', item.file)}
                                    className="bg-ta--blue text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors disabled:opacity-50"
                                    disabled={downloadingFiles.has(item.file)}
                                >
                                    {downloadingFiles.has(item.file) ? (
                                        <svg className="animate-spin h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <DashboardLayout>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
                {isInitialLoading ? (
                    <div className="flex items-center justify-center min-h-[400px]">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                    </div>
                ) : isSubscriptionActive ? (
                    <>
                        <div className="flex justify-between items-center p-4 container mx-auto p-5">
                            <h1 className="text-2xl">{t('information.fileUploaded', 'Files uploaded')}</h1>
                            <div className="flex space-between gap-4">
                                <Upload onUploadComplete={fetchFiles} />
                                
                                <Download />
                            </div>
                        </div>
                        <div className="container mx-auto p-5 border-b">
                            {renderFilesTable()}
                        </div>
                        <div className="flex justify-between items-center p-4 container mx-auto p-5">
                            <h1 className="text-2xl">{t('information.summary', 'Summary')}</h1>
                            <div className="w-[90%]">
                            <select
                                    value={fileType}
                                    onChange={(e) => setFileType(e.target.value)}
                                    className="border p-2 rounded"
                                >
                                    <option value="monthly">{t('information.fileTypes.monthly')}</option>
                                    <option value="quarterly">{t('information.fileTypes.quarterly')}</option>
                                </select>
                                
                                <select
                                    value={fileType === 'quarterly' ? quarter : month}
                                    onChange={(e) => {
                                        if (fileType === 'quarterly') {
                                            setQuarter(e.target.value);
                                        } else {
                                            setMonth(e.target.value);
                                        }
                                    }}
                                    className="border p-2 rounded"
                                >
                                    {fileType === 'quarterly' 
                                        ? quarters.map((q) => (
                                            <option key={q.value} value={q.value}>
                                                {t(`quarters.${q.value}`)}
                                            </option>
                                        ))
                                        : months.map((m) => (
                                            <option key={m.value} value={m.value}>
                                                {t(`information.months.${m.label}`)}
                                            </option>
                                        ))
                                    }
                                </select>
                                
                                <select
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    className="border p-2 rounded"
                                >
                                    {years.map((y) => (
                                        <option key={y} value={y}>{y}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {renderMainTabs()}
                        {renderCategoryTabs()}
                        <div className="p-4">
                            <div className="container mx-auto p-5 border-b">
                                {mainTab === 'SUMMARY' && renderSummaryTable()}
                                {mainTab === 'VAT DETAILED' && renderVatDetailedTable()}
                                {mainTab === 'TRANSACTION DETAILED' && renderTransactionDetailedTable()}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="relative">
                        <div className="absolute inset-0 bg-gray-200 bg-opacity-70 backdrop-blur-sm z-10 top-20 flex flex-col items-center justify-center">
                            <div className="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m0 0v2m0-2h2m-2 0H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <h3 className="text-xl font-bold text-gray-800 mb-2">Subscription Required</h3>
                                <p className="text-gray-600">Please activate your subscription to access the information dashboard</p>
                            </div>
                        </div>

                        <div className="filter blur-sm">
                            <div className="flex justify-between items-center p-4 container mx-auto p-5">
                                <h1 className="text-2xl">Files uploaded</h1>
                                <div className="flex items-center gap-4">
                                    <Upload onUploadComplete={fetchFiles} />
                                    <select
                                        value={fileType}
                                        onChange={(e) => setFileType(e.target.value)}
                                        className="border p-2 rounded"
                                    >
                                        <option value="monthly">{t('information.fileTypes.monthly')}</option>
                                        <option value="quarterly">{t('information.fileTypes.quarterly')}</option>
                                    </select>
                                    
                                    <select
                                        value={fileType === 'quarterly' ? quarter : month}
                                        onChange={(e) => {
                                            if (fileType === 'quarterly') {
                                                setQuarter(e.target.value);
                                            } else {
                                                setMonth(e.target.value);
                                            }
                                        }}
                                        className="border p-2 rounded"
                                    >
                                        {fileType === 'quarterly' 
                                            ? quarters.map((q) => (
                                                <option key={q.value} value={q.value}>
                                                    {t(`quarters.${q.value}`)}
                                                </option>
                                            ))
                                            : months.map((m) => (
                                                <option key={m.value} value={m.value}>
                                                    {t(`information.months.${m.label}`)}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    
                                    <select
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                        className="border p-2 rounded"
                                    >
                                        {years.map((y) => (
                                            <option key={y} value={y}>{y}</option>
                                        ))}
                                    </select>
                                    <Download />
                                </div>
                            </div>
                            <div className="container mx-auto p-5 border-b">
                                {renderFilesTable()}
                            </div>
                            <div className="flex justify-between items-center p-4 container mx-auto p-5">
                                <h1 className="text-2xl">Summary</h1>
                                <div className="flex items-center gap-4">
                                    <select
                                        value={fileType}
                                        onChange={(e) => setFileType(e.target.value)}
                                        className="border p-2 rounded"
                                    >
                                        <option value="monthly">{t('information.fileTypes.monthly')}</option>
                                        <option value="quarterly">{t('information.fileTypes.quarterly')}</option>
                                    </select>
                                    
                                    <select
                                        value={fileType === 'quarterly' ? quarter : month}
                                        onChange={(e) => {
                                            if (fileType === 'quarterly') {
                                                setQuarter(e.target.value);
                                            } else {
                                                setMonth(e.target.value);
                                            }
                                        }}
                                        className="border p-2 rounded"
                                    >
                                        {fileType === 'quarterly' 
                                            ? quarters.map((q) => (
                                                <option key={q.value} value={q.value}>
                                                    {t(`quarters.${q.value}`)}
                                                </option>
                                            ))
                                            : months.map((m) => (
                                                <option key={m.value} value={m.value}>
                                                    {t(`information.months.${m.label}`)}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    
                                    <select
                                        value={year}
                                        onChange={(e) => setYear(e.target.value)}
                                        className="border p-2 rounded"
                                    >
                                        {years.map((y) => (
                                            <option key={y} value={y}>{y}</option>
                                        ))}
                                    </select>
                                </div>
                                <Download />
                            </div>
                            
                            {renderMainTabs()}
                            {renderCategoryTabs()}
                            <div className="p-4">
                                <div className="container mx-auto p-5 border-b">
                                    {mainTab === 'SUMMARY' && renderSummaryTable()}
                                    {mainTab === 'VAT DETAILED' && renderVatDetailedTable()}
                                    {mainTab === 'TRANSACTION DETAILED' && renderTransactionDetailedTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
};

export default Information;
