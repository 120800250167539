import axios from 'axios';
import api from './api';
import axiosInstance from './axiosConfig';

export const getAllUsers = async () => {
  return api.get('/api/v1/users');
};

export const createUser = async (userData) => {
    const response = await axiosInstance.post('/api/v1/users', {
        email: userData.email,
        password: userData.password,
        username: userData.username,
        contact: userData.contact,
        plan: userData.plan
    });
    return response.data;
};
