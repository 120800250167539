import { PieChart } from '@mui/x-charts';

const YearlyProfitOverview = ({ pieData }) => {
    
    return (
        <div className="flex flex-col items-center p-5 bg-white rounded-lg justify-center  ">
            <PieChart
                series={[
                    {
                        data: pieData,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                                
                    },
                ]}
                width={350}
                height={350}
                slotProps={{
                    legend: {
                        hidden: true,
                       
                    }
                }}
            >
              
            </PieChart>

            {/* Legend */}
           
        </div>
        
    );
};

export default YearlyProfitOverview;
