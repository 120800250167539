import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './PaymentSuccess.css'; // Create this CSS file for styles
import DashboardLayout from '../layouts/dashboard';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/billing');
        }, 5000);
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <DashboardLayout>
            <div className="container mx-auto p-5 my-8">
                <div className="payment-success-container">
                    <div className="success-illustration">
                        <div className="check-circle">
                            <div className="checkmark"></div>
                        </div>
                        <div className="receipt">
                            <div className="receipt-lines">
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className="paid-stamp">PAID</div>
                        </div>
                    </div>
                    <h1>{t('paymentSuccess.title')}</h1>
                    <p>{t('paymentSuccess.message')}</p>
                    <button className="button-success" onClick={() => navigate('/billing')}>
                        {t('paymentSuccess.homeButton')}
                    </button>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default PaymentSuccess; 