import React, { useEffect, useRef, useState } from 'react';
import { CardElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import axiosInstance from '../services/axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const CheckoutForm = ({ clientSecret, setClientSecret, fetchPayments }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const timeoutRef = useRef(null); // Ref to store the timeout ID

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        
        // Remove localStorage dependency and use the plan data passed from BillingPlan
        const userSubscription = JSON.parse(localStorage.getItem('userSubscription'));
        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        const addressElement = elements.getElement(AddressElement);

        // Get the address details
        const { value: addressDetails } = await addressElement.getValue();

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    address: {
                        city: addressDetails.address.city,
                        country: addressDetails.address.country,
                        line1: addressDetails.address.line1,
                        line2: addressDetails.address.line2,
                        postal_code: addressDetails.address.postal_code,
                        state: addressDetails.address.state
                    },
                    name: addressDetails.name,
                },
            },
        });

        setLoading(false);

        if (result.error) {
            console.log(result);
            setError(result.error.message);
            toast.error(t('billing.checkout.paymentFailed'));
        } else if (result.paymentIntent.status === 'succeeded') {
            setSuccess(true);

            try {
                // Update payment status with the selected plan details
                const paymentIntentId = result.paymentIntent.id;
                const { data } = await axiosInstance.put(
                    `/api/v1/payments`,
                    {
                        paymentIntentId: paymentIntentId,
                        status: 'succeeded',
                        planType: "monthly", // Use the selected plan type
                        plan: userSubscription.plan,
                        price: userSubscription.price,
                    }
                );

                if (data) {
                    // Fetch updated subscription details
                    const subscriptionResponse = await axiosInstance.get('/api/v1/subscriptions/userSubscription');
                    if (subscriptionResponse.data?.data) {
                        // Update localStorage with new subscription data
                        localStorage.setItem('userSubscription', JSON.stringify(subscriptionResponse.data.data));
                    }

                    fetchPayments();
                    toast.success(t('billing.checkout.paymentSuccess'));
                } else {
                    toast.error(t('billing.checkout.updateFailed'));
                }
            } catch (err) {
                toast.error(err.response?.data?.message || t('billing.checkout.updateError'));
                console.error('Error updating payment status:', err);
            }

            // Optional timeout to reset client secret
            timeoutRef.current = setTimeout(() => {
                setClientSecret("");
            }, 1000);
        }
    };
    
    const handleCardChange = (event) => {
        setError(event.error ? event.error.message : null);
    };
    
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div className="checkout-form-container shadow-lg px-5 py-6 md:py-10 rounded-xl mb-8">
            <h2 className="font-medium text-xl mb-4">{t('billing.checkout.title')}</h2>
            {success ? (
                <div className="success-message bg-green-100 text-green-800 p-4 rounded-lg">
                    <p>{t('billing.checkout.successMessage')}</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="checkout-form space-y-6">
                    <div>
                        <label htmlFor="address-element" className="block mb-2 font-medium">
                            {t('billing.checkout.billingAddress')}
                        </label>
                        <AddressElement 
                            id="address-element"
                            options={{
                                mode: 'billing',
                                fields: {
                                    phone: 'never',
                                    postal_code: {
                                        mode: 'hidden'
                                    }
                                },
                                defaultValues: {
                                    name: '',
                                },
                            }}
                            className="border rounded-md p-3"
                        />
                    </div>
                    
                    <div>
                        <label htmlFor="card-element" className="block mb-2 font-medium">
                            {t('billing.checkout.cardDetails')}
                        </label>
                        <div className="border rounded-md p-3">
                            <CardElement
                                id="card-element"
                                onChange={handleCardChange}
                                options={{
                                    hidePostalCode: true,
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#32325d',
                                            '::placeholder': {
                                                color: '#a0aec0',
                                            },
                                        },
                                        invalid: {
                                            color: '#fa755a',
                                        },
                                    },
                                }}
                            />
                        </div>
                        {error && (
                            <div className="text-red-500 mt-2" role="alert">
                                {error}
                            </div>
                        )}
                    </div>
                    
                    <div className="flex justify-end">
                        <button 
                            type="button" 
                            onClick={() => setClientSecret("")}
                            className="mr-4 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-100"
                        >
                            {t('billing.checkout.cancelButton')}
                        </button>
                        <button 
                            type="submit" 
                            disabled={!stripe || loading} 
                            className={`px-4 py-2 rounded-lg text-white ${
                                !stripe || loading 
                                    ? 'bg-gray-400 cursor-not-allowed' 
                                    : 'bg-ta--blue hover:bg-ta--headingBlue'
                            }`}
                        >
                            {loading ? (
                                <div className="flex items-center">
                                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                                    {t('billing.checkout.processing')}
                                </div>
                            ) : t('billing.checkout.payButton')}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CheckoutForm;
