import React, { useState, useEffect } from 'react';
import DashboardLayout from '../layouts/dashboard';
import DataTable from 'react-data-table-component';
import { Pagination, PaginationItem, Button, IconButton } from '@mui/material';
import { FindInPage, SaveAltOutlined, Search, OpenInNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import BillingPlan from '../components/BillingPlan';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../services/axiosConfig';
import { format } from 'date-fns';

const Billing = () => {
    const { t } = useTranslation();
    const [formData3, setFormData3] = useState({});
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [clientSecret, setClientSecret] = useState();
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIP_PUB_KEY);
    const [totalRows, setTotalRows] = useState(0);
    const [filteredPayments, setFilteredPayments] = useState([]);

    const fetchPayments = async () => {
        try {
            const response = await axiosInstance.get('/api/v1/payments');
            if (Array.isArray(response.data.data)) {
                const paymentsData = response.data.data;
                setPayments(paymentsData);
                // Apply initial filtering and pagination
                handleDataFiltering(paymentsData, searchTerm);
            } else {
                setPayments([]);
                setFilteredPayments([]);
                console.error('Expected an array but got:', response.data);
            }
        } catch (err) {
            setError('Failed to fetch payments');
            console.error('Fetch error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // New function to handle data filtering
    const handleDataFiltering = (data, search) => {
        const filtered = data.filter(payment => 
            Object.values(payment).some(value => 
                String(value).toLowerCase().includes(search.toLowerCase())
            )
        );
        setFilteredPayments(filtered);
        setTotalRows(filtered.length);
    };

    // Update search handler
    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
        handleDataFiltering(payments, searchValue);
        setPage(1);
    };

    // Get current page data
    const getCurrentPageData = () => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredPayments.slice(start, end);
    };

    useEffect(() => {
        fetchPayments();
    }, []);

    // Add effect to handle filtering when payments change
    useEffect(() => {
        handleDataFiltering(payments, searchTerm);
    }, [payments, searchTerm]);

    const handleChange3Billing = (value) => {
        setFormData3((prev) => ({ ...prev, paymentMode: value.mode, amount: value.amount }));
    };

    const handleOpenInvoice = (url) => {
        window.open(url, '_blank');
    };

    const statusColors = {
        paid: { bg: 'bg-green-100', text: 'text-green-800', badge: 'bg-green-500' },
        pending: { bg: 'bg-yellow-100', text: 'text-yellow-800', badge: 'bg-yellow-500' },
        failed: { bg: 'bg-red-100', text: 'text-red-800', badge: 'bg-red-500' }
    };

    const columns = [
        { 
            name: t('billing.table.amount'), 
            selector: row => (row.amount / 100).toFixed(2), 
            sortable: true,
            cell: row => (
                <div className="font-medium">
                    {(row.amount / 100).toFixed(2)}
                </div>
            )
        },
        { 
            name: t('billing.table.currency'), 
            selector: row => row.currency, 
            sortable: true,
            cell: row => (
                <div className="uppercase font-medium">
                    {row.currency}
                </div>
            )
        },
        { 
            name: t('billing.table.status'), 
            selector: row => row.status, 
            sortable: true,
            cell: row => {
                const status = row.status.toLowerCase();
                const style = statusColors[status] || { bg: 'bg-gray-100', text: 'text-gray-800', badge: 'bg-gray-500' };
                
                return (
                    <div className={`px-3 py-1 rounded-full ${style.bg} ${style.text} inline-flex items-center`}>
                        <span className={`w-2 h-2 ${style.badge} rounded-full mr-2`}></span>
                        <span className="capitalize font-medium">{row.status}</span>
                    </div>
                );
            }
        },
        { 
            name: t('billing.table.paymentDate'), 
            selector: row => format(new Date(row.paymentDate), 'yyyy-MM-dd'), 
            sortable: true,
            cell: row => (
                <div className="font-medium">
                    {format(new Date(row.paymentDate), 'yyyy-MM-dd')}
                </div>
            )
        },
        {
            name: t('billing.table.invoice'),
            cell: row => (
                <IconButton
                    aria-label={t('billing.table.openInvoice')}
                    onClick={() => handleOpenInvoice(row.invoiceHostedURL)}
                    sx={{ 
                        color: '#1976d2',
                        '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            transform: 'scale(1.1)',
                        },
                        transition: 'all 0.2s'
                    }}
                    size="medium"
                >
                    <OpenInNew />
                </IconButton>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#f8fafc',
                borderRadius: '8px 8px 0 0',
                borderBottom: '1px solid #e2e8f0',
                fontWeight: 'bold',
            },
        },
        headCells: {
            style: {
                fontSize: '14px',
                padding: '16px',
                fontWeight: '600',
                color: '#475569',
            },
        },
        rows: {
            style: {
                backgroundColor: 'white',
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid #f1f5f9',
                },
                minHeight: '60px',
                fontSize: '14px',
            },
            highlightOnHoverStyle: {
                backgroundColor: '#f8fafc',
                cursor: 'pointer',
            },
        },
        cells: {
            style: {
                padding: '16px',
            },
        },
    };

    // Add a custom pagination component
    const CustomPagination = ({ currentPage, totalRows, rowsPerPage, onChangePage }) => {
        const totalPages = Math.ceil(totalRows / rowsPerPage);
        
        return (
            <div className="flex justify-center mt-4">
                <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => onChangePage(page)}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                        <PaginationItem
                            {...item}
                            sx={{
                                color: item.selected ? 'white' : '#1976d2',
                                backgroundColor: item.selected ? '#1976d2' : 'transparent',
                                '&:hover': {
                                    backgroundColor: item.selected ? '#1565c0' : '#e6f0ff',
                                },
                            }}
                        />
                    )}
                />
            </div>
        );
    };

    return (
        <DashboardLayout>
            <div className="container mx-auto p-5 my-8">
                {clientSecret && (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm 
                            clientSecret={clientSecret} 
                            setClientSecret={setClientSecret} 
                            fetchPayments={fetchPayments}
                        />
                    </Elements>
                )}
                <BillingPlan 
                    formData3={formData3} 
                    handleChange3Billing={handleChange3Billing} 
                    setClientSecret={setClientSecret} 
                    payments={filteredPayments}
                />
                <div className="w-full">
                    <p className="font-medium text-2xl md:text-3xl mb-3">{t('billing.table.title')}</p>
                    <div className="shadow-lg px-5 py-6 md:py-10 rounded-xl">
                        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between gap-4 mb-8">
                            <div className="flex flex-row items-center justify-start gap-2">
                                <div className="relative ms-4 border border-[#9E9E9E] rounded-xl p-2">
                                    <Search className="text-[#9E9E9E]" />
                                    <input
                                        className="ps-2 focus:outline-0"
                                        type="text"
                                        placeholder={t('billing.table.searchPlaceholder')}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>
                            
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-700"></div>
                            </div>
                        ) : error ? (
                            <div className="text-red-500 p-4 bg-red-50 rounded-lg border border-red-200">
                                <p className="flex items-center">
                                    <span className="mr-2">⚠️</span>
                                    {error}
                                </p>
                            </div>
                        ) : filteredPayments.length === 0 ? (
                            <div className="text-gray-500 p-8 text-center bg-gray-50 rounded-lg border border-gray-200">
                                <p>No payments found.</p>
                            </div>
                        ) : (
                            <>
                                <DataTable
                                    className="tx-tbl mb-6"
                                    columns={columns}
                                    data={getCurrentPageData()}
                                    customStyles={customStyles}
                                    pagination
                                    paginationComponent={() => (
                                        <CustomPagination
                                            currentPage={page}
                                            totalRows={totalRows}
                                            rowsPerPage={rowsPerPage}
                                            onChangePage={setPage}
                                        />
                                    )}
                                    noHeader
                                    persistTableHead
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default Billing;
