import { useEffect } from "react";
import CommonLayout from "../layouts/common";
import Banner from "../components/banner";
import PlansAndPricing from "../components/plans-and-pricing";
import Testimonials from "../components/testimonials";
import Tools from "../components/tools";
import ContactUs from "../components/contact-us";

const Home = () => {
    useEffect(() => {
        // Clear localStorage and sessionStorage on component mount
        localStorage.removeItem('userSubscription');
    }, []);
    
    return (
        <>
            <CommonLayout>
                <Banner />
                <section id="tools">
                    <Tools />
                </section>
                <section id="testimonials">
                    <Testimonials />
                </section>
                <section id="pricing">
                    <PlansAndPricing />
                </section>
                <section id="faq">
                    {/* Add your FAQ component here */}
                </section>
            </CommonLayout>
        </>
    );
}

export default Home;