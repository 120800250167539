import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '../layouts/dashboard';
import { Button, ButtonGroup, IconButton, InputAdornment, TextField, Alert, CircularProgress, Chip } from '@mui/material';
import { Visibility, VisibilityOff, Check as CheckIcon, Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import axiosInstance from '../services/axiosConfig';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Account = () => {
    const { t } = useTranslation();
    const [personalDetails, setPersonalDetails] = useState({
        username: '',
        contact: '',
        email: ''
    });
    const [passwordDetails, setPasswordDetails] = useState({});
    const [user, setUser] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false,
    });
    const [alert, setAlert] = useState({ type: '', message: '', visible: false });
    const [amazonToken, setAmazonToken] = useState('');
    const [isConnecting, setIsConnecting] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState({ success: false, message: '' });
    const [isAmazonConnected, setIsAmazonConnected] = useState(false);
    const [currentAmazonId, setCurrentAmazonId] = useState('');
    const navigate = useNavigate();
    const [redirectTimer, setRedirectTimer] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState({
        personalDetails: false,
        password: false
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axiosInstance.get('/api/v1/users/profile');
                setPersonalDetails(response.data.data);
                setUser(response.data.data);
                setIsAmazonConnected(!!response.data.data.amazonId);
                setCurrentAmazonId(response.data.data.amazonId || '');
                setAmazonToken(response.data.data.amazonId || '');
            } catch (err) {
                console.error('Failed to fetch profile:', err);
            }
        };

        fetchProfile();
    }, []);

    useEffect(() => {
        return () => {
            if (redirectTimer) {
                clearTimeout(redirectTimer);
            }
        };
    }, [redirectTimer]);

    const handlePersonalDetailsChange = (e) => {
        const { name, value } = e.target;
        setPersonalDetails(prev => ({ ...prev, [name]: value }));
    };

    const handlePasswordDetailsChange = (e) => {
        const { name, value } = e.target;
        setPasswordDetails({ ...passwordDetails, [name]: value });
        
        if (name === 'newPassword' || name === 'confirmNewPassword') {
            if (name === 'newPassword' && passwordDetails.confirmNewPassword && value !== passwordDetails.confirmNewPassword) {
                setPasswordError(t('account.password.mismatchError'));
            } else if (name === 'confirmNewPassword' && value !== passwordDetails.newPassword) {
                setPasswordError(t('account.password.mismatchError'));
            } else {
                setPasswordError('');
            }
        }
    };

    const handleCancel = () => {
        setPersonalDetails(user);
    };

    const handlePersonalDetailsSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(prev => ({ ...prev, personalDetails: true }));
        try {
            let userData = localStorage.getItem('userData');
            let userId = JSON.parse(userData).userId;
            await axiosInstance.put(`/api/v1/users/${userId}`, {
                username: personalDetails.username,
                alias: personalDetails.alias
            });
            toast.success(t('account.personalDetails.updateSuccess'));
        } catch (err) {
            if (err.response?.status === 401) {
                toast.error('Your session has expired. Please login again.');
            } else {
                toast.error(err.response?.data?.message || t('account.personalDetails.updateError'));
            }
        } finally {
            setIsLoading(prev => ({ ...prev, personalDetails: false }));
        }
    };

    const handlePasswordDetailsSubmit = async (e) => {
        e.preventDefault();
        
        // Validate passwords match
        if (passwordDetails.newPassword !== passwordDetails.confirmNewPassword) {
            toast.error(t('account.password.mismatchError'));
            return;
        }

        setIsLoading(prev => ({ ...prev, password: true }));
        const payload = {
            email: personalDetails.email,
            newPassword: passwordDetails.newPassword,
            password: passwordDetails.newPassword,
        };

        try {
            const response = await axiosInstance.put('/api/v1/users/reset-password', payload);
            toast.success(t('account.password.changeSuccess'));
            setPasswordDetails({});
        } catch (err) {
            toast.error(err.response?.data?.message || t('account.password.changeError'));
        } finally {
            setIsLoading(prev => ({ ...prev, password: false }));
        }
    };

    const togglePasswordVisibility = (field) => {
        setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const getAliasInitial = () => {
        return personalDetails.username ? personalDetails.username[0].toUpperCase() : '';
    };

    // Add a function to check if passwords are valid
    const isPasswordValid = () => {
        return !passwordError && 
               passwordDetails.newPassword && 
               passwordDetails.confirmNewPassword
    };

    const handleAmazonConnect = async () => {
        if (!amazonToken.trim()) {
            toast.error(t('account.amazonToken.emptyError'));
            return;
        }

        setIsConnecting(true);
        setConnectionStatus({ success: false, message: '' });

        try {
            await axiosInstance.put(`/api/v1/users/update/amazon/${amazonToken}`);
            setConnectionStatus({ 
                success: true, 
                message: t('account.amazonToken.success') 
            });
            setIsAmazonConnected(true);
            setCurrentAmazonId(amazonToken);
            toast.success(t('account.amazonToken.success'));
        } catch (err) {
            setConnectionStatus({ 
                success: false, 
                message: err.response?.data?.message || t('account.amazonToken.connectionError') 
            });
            toast.error(err.response?.data?.message || t('account.amazonToken.connectionError'));
        } finally {
            setIsConnecting(false);
        }
    };

    return (
        <DashboardLayout>
            <div className="container mx-auto px-4 py-8">
             
                <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                    <div className="flex items-center gap-4">
                        <div className="w-16 h-16 rounded-full bg-[#D4B106] flex items-center justify-center text-white text-2xl">
                            {getAliasInitial()}
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold">{t('account.welcome')}, {personalDetails.alias}!</h1>
                            <p>{personalDetails.username?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                                <TextField
                                    type="text"
                                    value={amazonToken}
                                    onChange={(e) => setAmazonToken(e.target.value)}
                                    className="w-full"
                                    placeholder={t('account.amazonToken.placeholder')}
                                    disabled={isConnecting}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {isAmazonConnected ? (
                                                    <LinkIcon color="success" />
                                                ) : (
                                                    <LinkOffIcon color="error" />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleAmazonConnect}
                                    disabled={isConnecting || !amazonToken.trim()}
                                    className={`min-w-[120px] ${
                                        isAmazonConnected 
                                            ? 'bg-green-600 hover:bg-green-700' 
                                            : 'bg-ta--blue hover:bg-ta--headingBlue'
                                    }`}
                                >
                                    {isConnecting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : isAmazonConnected ? (
                                        t('account.tabs.updateToken')
                                    ) : (
                                        t('account.tabs.connect')
                                    )}
                                </Button>
                            </div>
                            {isAmazonConnected && (
                                <Chip
                                    icon={<CheckIcon />}
                                    label={`${t('account.amazonToken.connected', { id: currentAmazonId })} (${currentAmazonId})`}
                                    color="success"
                                    variant="outlined"
                                    className="w-fit"
                                />
                            )}
                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto p-5 my-8">
                    <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                        <div className="w-full md:w-3/5">
                            <form onSubmit={handlePersonalDetailsSubmit} className="account--form tx--shadow px-5 py-6 md:py-10 rounded-xl">
                                <p className="font-medium text-2xl md:text-3xl mb-3">
                                    {t('account.personalDetails.title')}
                                </p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    <div>
                                        <label className="block mb-1">{t('account.personalDetails.username')}</label>
                                        <TextField
                                            type="text"
                                            name="username"
                                            value={personalDetails.username || ''}
                                            onChange={handlePersonalDetailsChange}
                                            className="w-full"
                                            placeholder={t('account.personalDetails.aliasPlaceholder')}
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-1">{t('account.personalDetails.email')}</label>
                                        <TextField
                                            type="email"
                                            name="email"
                                            value={personalDetails.email || ''}
                                            onChange={handlePersonalDetailsChange}
                                            className="w-full"
                                            placeholder={t('account.personalDetails.emailPlaceholder')}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-1">{t('account.personalDetails.nameAndSurname')}</label>
                                        <TextField
                                            type="text"
                                            name="alias"
                                            value={personalDetails.alias || ''}
                                            onChange={handlePersonalDetailsChange}
                                            className="w-full"
                                            placeholder={t('account.personalDetails.nameAndSurnamePlaceholder')}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 flex items-center justify-end gap-3">
                                    <button
                                        type="submit"
                                        disabled={isLoading.personalDetails}
                                        className="min-w-[80px] md:min-w-[120px] px-4 py-3 text-base md:text-lg text-white bg-ta--blue transition-all shadow rounded-lg hover:bg-ta--headingBlue disabled:bg-gray-400 disabled:cursor-not-allowed"
                                    >
                                        {isLoading.personalDetails ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) : (
                                            t('account.actions.change')
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="w-full md:w-2/6">
                            <form onSubmit={handlePasswordDetailsSubmit} className="account--form tx--shadow px-5 py-6 md:py-10 rounded-xl">
                                <p className="font-medium text-2xl md:text-3xl mb-3">{t('account.password.title')}</p>
                                <div className="grid grid-cols-1 gap-5">
                                  
                                    <div>
                                        <label className="block mb-1">{t('account.password.newPassword')}</label>
                                        <TextField
                                            type={showPassword.newPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            value={passwordDetails.newPassword || ''}
                                            onChange={handlePasswordDetailsChange}
                                            className="w-full"
                                            placeholder={t('account.password.newPasswordPlaceholder')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => togglePasswordVisibility('newPassword')}
                                                            edge="end"
                                                        >
                                                            {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-1">{t('account.password.confirmNewPassword')}</label>
                                        <TextField
                                            type={showPassword.confirmNewPassword ? 'text' : 'password'}
                                            name="confirmNewPassword"
                                            value={passwordDetails.confirmNewPassword || ''}
                                            onChange={handlePasswordDetailsChange}
                                            className="w-full"
                                            placeholder={t('account.password.confirmNewPasswordPlaceholder')}
                                            error={!!passwordError}
                                            helperText={passwordError}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => togglePasswordVisibility('confirmNewPassword')}
                                                            edge="end"
                                                        >
                                                            {showPassword.confirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 flex items-center justify-end gap-3">
                                    <button
                                        type="submit"
                                        disabled={!isPasswordValid() || isLoading.password}
                                        className={`min-w-[80px] md:min-w-[120px] px-4 py-3 text-base md:text-lg text-white transition-all shadow rounded-lg ${
                                            isPasswordValid() && !isLoading.password
                                            ? 'bg-ta--blue hover:bg-ta--headingBlue' 
                                            : 'bg-gray-400 cursor-not-allowed'
                                        }`}
                                    >
                                        {isLoading.password ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) : (
                                            t('account.actions.change')
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                
            </div>
        </DashboardLayout>
    );
};

export default Account;
