import React, { useState } from "react";
import {
    Popover,
    Radio,
    RadioGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import axiosInstance from '../services/axiosConfig';

const Download = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selection, setSelection] = useState("");
    const [month, setMonth] = useState("");
    const [quarter, setQuarter] = useState("");
    const [year, setYear] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    // ... existing code for handleClick, handleClose ...
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRadioChange = (event) => {
        setSelection(event.target.value);
        setMonth("");
        setQuarter("");
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleQuarterChange = (event) => {
        setQuarter(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from(
        { length: Math.max(1, currentYear - 2023) }, 
        (_, i) => 2024 + i
    ).sort((a, b) => b - a);

    const monthMap = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };

    const monthToEnglishMap = {
        "January": "January",
        "February": "February",
        "March": "March",
        "April": "April",
        "May": "May",
        "June": "June",
        "July": "July",
        "August": "August",
        "September": "September",
        "October": "October",
        "November": "November",
        "December": "December",
        // German months
        "Januar": "January",
        "Februar": "February",
        "März": "March",
        "Mai": "May",
        "Juni": "June",
        "Juli": "July",
        "Oktober": "October",
        "Dezember": "December",
        // Spanish months
        "Enero": "January",
        "Febrero": "February",
        "Marzo": "March",
        "Abril": "April",
        "Junio": "June",
        "Julio": "July",
        "Agosto": "August",
        "Septiembre": "September",
        "Octubre": "October",
        "Noviembre": "November",
        "Diciembre": "December",
        // French months
        "Janvier": "January",
        "Février": "February",
        "Mars": "March",
        "Avril": "April",
        "Mai": "May",
        "Juin": "June",
        "Juillet": "July",
        "Août": "August",
        "Septembre": "September",
        "Octobre": "October",
        "Novembre": "November",
        "Décembre": "December",
        // Italian months
        "Gennaio": "January",
        "Febbraio": "February",
        "Marzo": "March",
        "Aprile": "April",
        "Maggio": "May",
        "Giugno": "June",
        "Luglio": "July",
        "Agosto": "August",
        "Settembre": "September",
        "Ottobre": "October",
        "Novembre": "November",
        "Dicembre": "December"
    };

    const handleDownload = async () => {
        if (!selection || !year || (selection === "monthly" && !month) || (selection === "quarterly" && !quarter)) {
            setMessage("Please fill in all required fields");
            setIsSuccess(false);
            return;
        }

        const requestData = {
            fileType: selection,
            year: year,
            ...(selection === "monthly" ? { 
                month: monthMap[monthToEnglishMap[month] || month],
                quarter: "Q1"
            } : { 
                quarter: quarter,
                month: 1
            }),
            fileExtension: "pdf"
        };

        try {
            setIsLoading(true);
            setMessage("");
            const response = await axiosInstance.post('/api/v1/data/downloadFile', requestData, {
                responseType: 'blob'
            });

            // Check if blob size is 0
            if (response.data.size === 0) {
                setMessage("No data available for download");
                setIsSuccess(false);
                return;
            }

            // Create a download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${selection}_report_${year}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setMessage("File downloaded successfully");
            setIsSuccess(true);
            handleClose();
        } catch (error) {
            setMessage(error.response?.data?.message || "Download failed");
            setIsSuccess(false);
            console.error('Download error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const isPopoverOpen = Boolean(anchorEl);

    return (
        <div className="p-4 relative">
            <button
                onClick={handleClick}
                className={`px-4 py-3 text-sm md:text-base text-white bg-ta--blue transition-all shadow rounded-lg hover:bg-ta--headingBlue`}
            >
                {t('download.button')}
            </button>

            <Popover
                id={isPopoverOpen ? 'simple-popover' : undefined}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={`download--box`}
            >
                <div className="p-8 w-[300px] md:w-[500px] lg:w-[600px]">
                    <div className="text-center mb-6">
                        <CloudDownloadOutlinedIcon fontSize="large" className="text-[#1849D6] block mx-auto mb-4" />
                        <Typography variant="h6">{t('download.title')}</Typography>
                    </div>

                    <div className="mt-4">
                        <RadioGroup
                            aria-label={t('download.reportType')}
                            value={selection}
                            onChange={handleRadioChange}
                            style={{ flexFlow: "row" }}
                        >
                            <FormControlLabel
                                className="download-box--label"
                                value="monthly"
                                control={<Radio />}
                                label={t('download.monthlyReport')}
                            />
                            <FormControlLabel
                                className="download-box--label"
                                value="quarterly"
                                control={<Radio />}
                                label={t('download.quarterlyReport')}
                            />
                        </RadioGroup>
                    </div>

                    {(selection === "monthly" || selection === "quarterly") && (
                        <div className="download-box--inputs flex items-center justify-between mt-2 bg-white w-full">
                            {selection === "monthly" && (
                                <FormControl fullWidth>
                                    <label>{t('download.month')}</label>
                                    <Select
                                        className="download-box--select"
                                        value={month}
                                        onChange={handleMonthChange}
                                    >
                                        {t('download.months', { returnObjects: true }).map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            {selection === "quarterly" && (
                                <FormControl fullWidth>
                                    <label>{t('download.quarter')}</label>
                                    <Select
                                        className="download-box--select"
                                        value={quarter}
                                        onChange={handleQuarterChange}
                                    >
                                        {["Q1", "Q2", "Q3", "Q4"].map((q, index) => (
                                            <MenuItem key={index} value={q}>
                                                {q}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControl fullWidth>
                                <label>{t('download.year')}</label>
                                <Select
                                    className="download-box--select"
                                    value={year}
                                    onChange={handleYearChange}
                                >
                                    {years?.map((y, index) => (
                                        <MenuItem key={index} value={y}>
                                            {y}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}

                    <div className="mt-4 flex justify-end gap-2">
                        <button
                            className={`px-4 py-3 text-sm md:text-base text-[#666666] bg-[#ECECEC] transition-all shadow rounded-lg hover:bg-[#DDD] download-box--btn cancel-btn`}
                            onClick={handleClose}
                        >
                            {t('download.cancel')}
                        </button>
                        <button
                            onClick={handleDownload}
                            className={`px-4 py-3 text-sm md:text-base text-white bg-ta--blue transition-all shadow rounded-lg hover:bg-ta--headingBlue download-box--btn`}
                            disabled={isLoading}
                        >
                            {isLoading ? <div className="w-6 h-6 border-4 border-t-4 border-white rounded-full animate-spin"></div> : t('download.downloadButton')}
                        </button>
                    </div>

                    {message && (
                        <Typography className={`mt-4 text-center ${isSuccess ? 'text-green-500 font-bold' : 'text-red-500'}`}>
                            {message}
                        </Typography>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default Download; 