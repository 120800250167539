// assets

import './App.css';

// modules
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

// utils
import history from "./utils/history";

// routes
import Account from './routes/account';
import Billing from './routes/billing';
import Graphics from './routes/graphics';
import Home from './routes/home';
import Information from './routes/information';
import Signin from './routes/signin';
import Signup from './routes/signup';
import ContactPage from './routes/contact-us';
import ForgotPassword from './routes/forgot-password';
import ResetPassword from './routes/reset-password';
import PaymentSuccess from './routes/PaymentSuccess';
import PaymentFail from './routes/PaymentFail';
import PrivacyPolicy from './routes/privacy-policy';
import TermsOfService from './routes/terms-of-service';
import FAQ from './routes/faq';

// Protected Route component
const ProtectedRoute = ({ children }) => {
	const userData = JSON.parse(localStorage.getItem('userData'));
	if (!userData || !userData.jwtToken) {
		return <Navigate to="/signin" />;
	}
	return children;
};

// Add this function before the App component
const checkTokenExpiration = () => {
	const userData = JSON.parse(localStorage.getItem('userData'));
	if (userData && userData.jwtToken) {
		// Get token creation time (you'll need to store this when setting the token)
		const tokenCreationTime = userData.tokenCreationTime || Date.now();
		const currentTime = Date.now();
		const timeElapsed = currentTime - tokenCreationTime;
		
		// Check if 24 hours (in milliseconds) have passed
		if (timeElapsed > 24 * 60 * 60 * 1000) {
			localStorage.removeItem('userData');
			window.location.href = '/signin';
		}
	}
};

function App() {
	// Add useEffect to check token expiration
	React.useEffect(() => {
		// Check immediately when app loads
		checkTokenExpiration();
		
		// Set up interval to check every minute
		const interval = setInterval(checkTokenExpiration, 60000);
		
		// Cleanup interval on component unmount
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Router history={history}>
				<Routes>
					<Route path={`/`} element={<Home />} />
					<Route path={`/privacy-policy`} element={<PrivacyPolicy />} />
					<Route path={`/terms-and-service`} element={<TermsOfService />} />
					<Route path={`/faq`} element={<FAQ />} />
					<Route path={`/account`} element={
						<ProtectedRoute>
							<Account />
						</ProtectedRoute>
					} />
					<Route path={`/billing`} element={
						<ProtectedRoute>
							<Billing />
						</ProtectedRoute>
					} />
					<Route path={`/graphics`} element={
						<ProtectedRoute>
							<Graphics />
						</ProtectedRoute>
					} />
					<Route path={`/information`} element={
						<ProtectedRoute>
							<Information />
						</ProtectedRoute>
					} />
					<Route path={`/contact-us`} element={<ContactPage />} />
					<Route path={`/signin`} element={<Signin />} />
					<Route path={`/signup`} element={<Signup />} />
					<Route path={`/forgot-password`} element={<ForgotPassword />} />
					<Route path={`/reset-password`} element={<ResetPassword />} />
					<Route path={`/payment-success`} element={<PaymentSuccess />} />
					<Route path={`/payment-fail`} element={<PaymentFail />} />
					
				</Routes>
			</Router>
			<ToastContainer />
		</>
	);
}

export default App;
