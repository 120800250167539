import { useState, useEffect } from 'react';
import DashboardLayout from '../layouts/dashboard';
import { PieChart } from '@mui/x-charts';
import { Line } from 'react-chartjs-2';
import axiosInstance from '../services/axiosConfig';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import YearlyProfitOverview from '../components/YearlyProfitOverview';
import CountrySalesBarChart from '../components/CountrySalesBarChart';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const Graphics = () => {
    const { t } = useTranslation();
    const [fileType, setFileType] = useState('monthly');
    const [month, setMonth] = useState('1');
    const [quarter, setQuarter] = useState('Q1');
    const [year, setYear] = useState(new Date().getFullYear());
    const [data, setData] = useState([]);
    const [salesTypeData, setSalesTypeData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [totalProfit, setTotalProfit] = useState(0);
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);

    const months = [
        { value: '1', label: t('upload.months.0') },
        { value: '2', label: t('upload.months.1') },
        { value: '3', label: t('upload.months.2') },
        { value: '4', label: t('upload.months.3') },
        { value: '5', label: t('upload.months.4') },
        { value: '6', label: t('upload.months.5') },
        { value: '7', label: t('upload.months.6') },
        { value: '8', label: t('upload.months.7') },
        { value: '9', label: t('upload.months.8') },
        { value: '10', label: t('upload.months.9') },
        { value: '11', label: t('upload.months.10') },
        { value: '12', label: t('upload.months.11') }
    ];

    const quarters = [
        { value: 'Q1', label: t('quarters.Q1', 'First Quarter') },
        { value: 'Q2', label: t('quarters.Q2', 'Second Quarter') },
        { value: 'Q3', label: t('quarters.Q3', 'Third Quarter') },
        { value: 'Q4', label: t('quarters.Q4', 'Fourth Quarter') }
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from(
        { length: Math.max(1, currentYear - 2023) }, 
        (_, i) => 2024 + i
    ).sort((a, b) => b - a);

    const clearData = () => {
        setData([]);
        setSalesTypeData([]);
        setLineChartData([]);
        setPieData([]);
        setTotalProfit(0);
    };

    const fetchData = async () => {
        // Clear previous data before fetching new data
        clearData();

        try {
            const requestBody = {
                fileType,
                month: fileType === 'monthly' ? month : 1,
                quarter: fileType === 'quarterly' ? quarter : 'Q1',
                year,
            };
            const response = await axiosInstance.post('/api/v1/data/get-json', requestBody);
            const responseData = response.data.data;

            // Process response data to set state for graphs
            setData(responseData);
            processResponseData(responseData);

            // Show success toast
            // toast.success('Data loaded successfully');
        } catch (error) {
            console.error('Error fetching data:', error);
            // Show error toast with specific message if available
            // toast.error(error.response?.data?.message || 'Error loading data');
        }
    };

    const checkSubscriptionStatus = async () => {
        try {
            const response = await axiosInstance.get('/api/v1/subscriptions/userSubscription');
            setIsSubscriptionActive(response.data.data.active);
        } catch (error) {
            console.error('Error checking subscription:', error);
            setIsSubscriptionActive(false);
        } finally {
            setIsInitialLoading(false);
        }
    };

    const processResponseData = (responseData) => {
        let totalSales = 0;
        let totalRefunds = 0;
        const countryData = [];
        const salesTypes = {};

        responseData?.forEach((country) => {
            let countrySales = 0;
            let countryRefunds = 0;
            let countryVAT = 0;

            if (!country.transactionCategories || country.transactionCategories.length === 0) {
                console.warn(`No transaction categories for country: ${country.country}`);
                return;
            }

            country.transactionCategories.forEach((category) => {
                if (!category.transactions) return;

                const salesTotal = (category.transactions.TRANSACTION || [])
                    .filter((t) => t.transaction_type === 'SALE')
                    .reduce((acc, t) => acc + t.total, 0);

                const refundTotal = (category.transactions.TRANSACTION || [])
                    .filter((t) => t.transaction_type === 'REFUND')
                    .reduce((acc, t) => acc + Math.abs(t.total), 0);

                const vatTotal = (category.transactions.VAT || []).reduce((acc, v) => acc + v.vat, 0);

                countrySales += salesTotal;
                countryRefunds += refundTotal;
                countryVAT += vatTotal;

                // Aggregate sales types
                if (!salesTypes[category.category]) {
                    salesTypes[category.category] = 0;
                }
                salesTypes[category.category] += salesTotal;
            });

            countryData.push({
                country: country.country,
                sales: countrySales,
                refunds: countryRefunds,
                vat: countryVAT,
            });

            totalSales += countrySales;
            totalRefunds += countryRefunds;
        });

        setTotalProfit(totalSales - totalRefunds);
        setPieData([
            { id: 1, label: 'Sales', value: totalSales, color: '#145895' },
            { id: 2, label: 'Refunds', value: totalRefunds, color: '#D3C033' },


        ]);
        setSalesTypeData(
            Object?.entries(salesTypes)?.map(([key, value]) => ({
                label: key,
                value,
                color: generateColor(key),
            }))
        );
        setLineChartData(countryData);
    };

    const generateColor = (label) => {
        // Fixed color for REGULAR
        if (label === 'REGULAR') return '#D3C033';  // Yellow

        // Predefined array of 10 distinct colors (excluding yellow)
        const colorPalette = [
            '#145895', // Blue
            '#E74C3C', // Red
            '#2ECC71', // Green
            '#9B59B6', // Purple
            '#3498DB', // Light Blue
            '#E67E22', // Orange
            '#16A085', // Teal
            '#8E44AD', // Dark Purple
            '#2980B9', // Royal Blue
            '#D35400'  // Dark Orange
        ];

        // Generate a consistent index based on the label string
        const hashCode = label.split('').reduce((acc, char) => {
            return char.charCodeAt(0) + ((acc << 5) - acc);
        }, 0);

        // Use modulo to get a consistent color from the palette
        const index = Math.abs(hashCode) % colorPalette.length;
        return colorPalette[index];
    };
    const lineChartDataConfig = {
        labels: lineChartData?.map((item) => item.country),
        datasets: [
            {
                label: 'Sales',
                data: lineChartData?.map((item) => item.sales),
                borderColor: '#D3C033',
                backgroundColor: 'rgba(211, 192, 51, 0.2)',
                fill: 'origin',
                tension: 0.4,
            },
            {
                label: 'Refunds',
                data: lineChartData.map((item) => item.refunds),
                borderColor: '#145895',
                backgroundColor: 'rgba(20, 88, 149, 0.2)',
                fill: 'origin',
                tension: 0.4,
            },
            {
                label: 'VAT',
                data: lineChartData.map((item) => item.vat),
                borderColor: '#006699',
                backgroundColor: 'rgba(0, 102, 153, 0.2)',
                fill: 'origin',
                tension: 0.4,
            },
        ],
    };

    useEffect(() => {
        fetchData();
        checkSubscriptionStatus();
    }, [fileType, month, quarter, year]);

    return (
        <DashboardLayout>
            <div className="container mx-auto p-5 my-8">
                {/* Filters */}
                <div className="mb-5 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div>
                        <label className="block text-lg font-semibold mb-2">{t('graphics.fileType', 'File Type')}</label>
                        <select
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        >
                            <option value="monthly">{t('graphics.monthly', 'Monthly')}</option>
                            <option value="quarterly">{t('graphics.quarterly', 'Quarterly')}</option>
                        </select>
                    </div>
                    <div>
                        <label className="block text-lg font-semibold mb-2">
                            {fileType === 'monthly' ? t('graphics.month', 'Month') : t('graphics.quarter', 'Quarter')}
                        </label>
                        <select
                            value={fileType === 'monthly' ? month : quarter}
                            onChange={(e) => fileType === 'monthly' ? setMonth(e.target.value) : setQuarter(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        >
                            {fileType === 'monthly'
                                ? months.map((m) => (
                                    <option key={m.value} value={m.value}>
                                        {m.label}
                                    </option>
                                ))
                                : quarters.map((q) => (
                                    <option key={q.value} value={q.value}>
                                        {q.label}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div>
                        <label className="block text-lg font-semibold mb-2">{t('graphics.year', 'Year')}</label>
                        <select
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        >
                            {years.map((y) => (
                                <option key={y} value={y}>
                                    {y}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {isInitialLoading ? (
                    <div className="flex items-center justify-center min-h-[400px]">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                    </div>
                ) : isSubscriptionActive ? (
                    <>
                        {/* Graphs */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                            {/* Pie Chart: Sales vs Refunds */}
                            <div className="shadow-lg p-5 rounded-lg ">
                                <h3 className="text-lg font-semibold mb-4">{t('graphics.salesVsRefunds', 'Sales vs Refunds')}</h3>
                                <div className="flex items-center justify-between">
                                    <div className="w-[58%]">
                                        <YearlyProfitOverview pieData={pieData} />
                                    </div>
                                    <div className="w-[42%]">
                                        <div className="space-y-4">
                                            {pieData?.map((item) => (
                                                <div key={item.id} className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div 
                                                            className="w-4 h-4 rounded-full mr-2" 
                                                            style={{ backgroundColor: item.color }}
                                                        ></div>
                                                        <span className="font-medium">{item.label}</span>
                                                    </div>
                                                    <span className="font-semibold">
                                                        €{item.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                    </span>
                                                </div>
                                            ))}
                                            {pieData?.length >= 2 && (
                                                <div className="border-t pt-3 mt-3">
                                                    <div className="flex items-center justify-between">
                                                        <span className="font-medium">{t('graphics.netTotal', 'Net Total')}</span>
                                                        <span className="font-bold text-green-600">
                                                            €{(pieData[0].value - pieData[1].value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Pie Chart: Sales Types */}
                            <div className="shadow-lg p-5 rounded-lg">
                                <h3 className="text-lg font-semibold mb-4">{t('graphics.salesTypes', 'Sales Types')}</h3>
                                <div className="flex items-center justify-between">
                                    <div className="w-[58%]">
                                        <YearlyProfitOverview pieData={salesTypeData} />
                                    </div>  
                                    <div className="w-[42%]">
                                        <div className="space-y-4">

                                            {salesTypeData?.map((item) => (
                                                <div key={item.label} className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div 
                                                            className="w-4 h-4 rounded-full mr-2" 
                                                            style={{ backgroundColor: item.color }}
                                                        ></div>
                                                        <span className="font-medium">{item.label}</span>
                                                    </div>
                                                    <span className="font-semibold">
                                                        €{item.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                    </span>
                                                </div>
                                            ))}
                                            {salesTypeData?.length > 0 && (
                                                <div className="border-t pt-3 mt-3">
                                                    <div className="flex items-center justify-between">
                                                        <span className="font-medium">{t('graphics.total', 'Total')}</span>
                                                        <span className="font-bold">
                                                            €{salesTypeData.reduce((sum, item) => sum + item.value, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Line Chart */}
                        <div className="mt-8 shadow-lg p-5 rounded-lg">
                            <h3 className="text-lg font-semibold mb-4">{t('graphics.salesByCountry', 'Sales, Refunds, and VAT by Country')}</h3>
                            <Line
                                data={lineChartDataConfig}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        title: {
                                            display: true,
                                            text: t('graphics.salesByCountry', 'Sales, Refunds, and VAT by Country'),
                                        },
                                        legend: {
                                            position: 'top',
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                color: 'rgba(0, 0, 0, 0.1)',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                        <div className="mt-8 shadow-lg p-5 rounded-lg">
                            <h3 className="text-lg font-semibold mb-4">{t('graphics.profitOverview', 'Profit Overview')}</h3>
                            <CountrySalesBarChart data={data} />
                        </div>
                    </>
                ) : (
                    <div className="relative">
                        {/* Locked overlay */}
                        <div className="absolute inset-0 bg-gray-200 bg-opacity-70 backdrop-blur-sm z-10 flex flex-col items-center justify-center">
                            <div className="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto mb-4 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m0 0v2m0-2h2m-2 0H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <h3 className="text-xl font-bold text-gray-800 mb-2">{t('graphics.subscriptionRequired', 'Subscription Required')}</h3>
                                <p className="text-gray-600">{t('graphics.subscriptionMessage', 'Please activate your subscription to access the analytics dashboard')}</p>
                            </div>
                        </div>

                        {/* Blurred content */}
                        <div className="filter blur-sm">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                                {/* Pie Chart: Sales vs Refunds */}
                                <div className="shadow-lg p-5 rounded-lg ">
                                    <h3 className="text-lg font-semibold mb-4">{t('graphics.salesVsRefunds', 'Sales vs Refunds')}</h3>
                                    <div className="flex items-center justify-between">
                                        <div className="w-[58%]">
                                            <YearlyProfitOverview pieData={pieData} />
                                        </div>
                                        <div className="w-1/3">
                                            <div className="space-y-4">
                                                {pieData?.map((item) => (
                                                    <div key={item.id} className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                            <div 
                                                                className="w-4 h-4 rounded-full mr-2" 
                                                                style={{ backgroundColor: item.color }}
                                                            ></div>
                                                            <span className="font-medium">{item.label}</span>
                                                        </div>
                                                        <span className="font-semibold">
                                                            €{item.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </span>
                                                    </div>
                                                ))}
                                                {pieData?.length >= 2 && (
                                                    <div className="border-t pt-3 mt-3">
                                                        <div className="flex items-center justify-between">
                                                            <span className="font-medium">{t('graphics.netTotal', 'Net Total')}</span>
                                                            <span className="font-bold text-green-600">
                                                                €{(pieData[0].value - pieData[1].value).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Pie Chart: Sales Types */}
                                <div className="shadow-lg p-5 rounded-lg">
                                    <h3 className="text-lg font-semibold mb-4">{t('graphics.salesTypes', 'Sales Types')}</h3>
                                    <div className="flex items-center justify-between">
                                        <div className="w-[58%]">
                                            <YearlyProfitOverview pieData={salesTypeData} />
                                        </div>
                                        <div className="w-[42%]">
                                            <div className="space-y-4">

                                                {salesTypeData?.map((item) => (
                                                    <div key={item.label} className="flex items-center justify-between">
                                                        <div className="flex items-center">
                                                            <div 
                                                                className="w-4 h-4 rounded-full mr-2" 
                                                                style={{ backgroundColor: item.color }}
                                                            ></div>
                                                            <span className="font-medium">{item.label}</span>
                                                        </div>
                                                        <span className="font-semibold">
                                                            €{item.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                        </span>
                                                    </div>
                                                ))}
                                                {salesTypeData?.length > 0 && (
                                                    <div className="border-t pt-3 mt-3">
                                                        <div className="flex items-center justify-between">
                                                            <span className="font-medium">{t('graphics.total', 'Total')}</span>
                                                            <span className="font-bold">
                                                                €{salesTypeData.reduce((sum, item) => sum + item.value, 0).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Line Chart */}
                            <div className="mt-8 shadow-lg p-5 rounded-lg">
                                <h3 className="text-lg font-semibold mb-4">{t('graphics.salesByCountry', 'Sales, Refunds, and VAT by Country')}</h3>
                                <Line
                                    data={lineChartDataConfig}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            title: {
                                                display: true,
                                                text: 'Sales, Refunds, and VAT by Country',
                                            },
                                            legend: {
                                                position: 'top',
                                            },
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false,
                                                },
                                            },
                                            y: {
                                                beginAtZero: true,
                                                grid: {
                                                    color: 'rgba(0, 0, 0, 0.1)',
                                                },
                                            },
                                        },
                                        elements: {
                                            line: {
                                                borderWidth: 2,
                                            },
                                            point: {
                                                radius: 3,
                                                borderWidth: 2,
                                            },
                                        },
                                    }}
                                />
                            </div>
                            <div className="mt-8 shadow-lg p-5 rounded-lg">
                                <h3 className="text-lg font-semibold mb-4">{t('graphics.profitOverview', 'Profit Overview')}</h3>
                                <CountrySalesBarChart data={data} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
};

export default Graphics;
