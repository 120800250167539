import { useState } from "react";
import { useTranslation } from "react-i18next";
import AuthLayout from "../layouts/auth";
import { requestPasswordReset } from "../services/auth"; // You'll need to create this API function

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        
        try {
            const response = await requestPasswordReset({ email });
            if (response.statusCode === 200) {
                setSuccess(true);
            } else {
                setError(t('forgotPassword.error'));
            }
        } catch (err) {
            setError(err.response?.data?.message || t('forgotPassword.error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout>
            <div className="container mx-auto px-5">
                <div className="border border-white rounded-[2rem] bg-[#FFFFFF50] px-[2rem] md:px-[3.5rem] lg:px-[5rem] py-[1.25rem] md:py-[2rem] lg:py-[3rem] w-full max-w-[300px] md:max-w-[500px] lg:max-w-[700px] mx-auto">
                    {success ? (
                        <div className="text-center">
                            <p className="text-white font-bold text-lg md:text-xl lg:text-3xl mb-6">
                                {t('forgotPassword.checkEmail')}
                            </p>
                            <p className="text-white">
                                {t('forgotPassword.emailSent')}
                            </p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <p className="text-white font-bold text-lg md:text-xl lg:text-3xl mb-6 md:mb-5 lg:mb-8">
                                {t('forgotPassword.title')}
                            </p>
                            <p className="text-white mb-6">
                                {t('forgotPassword.description')}
                            </p>

                            {error && <p className="text-red-500 mb-4">{error}</p>}

                            <label className="text-base md:text-lg text-white font-bold block">
                                {t('forgotPassword.emailLabel')}
                            </label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-6 md:mb-8 focus:outline-none"
                                required
                            />

                            <button
                                type="submit"
                                disabled={loading}
                                className="block mx-auto px-[3rem] md:px-[5rem] lg:px-[8rem] py-2 rounded-lg bg-ta--yellow border border-ta--yellow text-white text-sm md:text-base font-bold transition-all hover:bg-transparent hover:text-ta--yellow"
                            >
                                {loading ? t('forgotPassword.loading') : t('forgotPassword.submitButton')}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </AuthLayout>
    );
};

export default ForgotPassword; 