import React, { useState, useEffect } from "react";
import {
    Button,
    Popover,
    Radio,
    RadioGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import axiosInstance from '../services/axiosConfig';
import { toast } from "react-toastify";
import axios from "axios";

const monthToEnglishMap = {
    // English months
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    // German months
    "Januar": "January",
    "Februar": "February",
    "März": "March",
    "Mai": "May",
    "Juni": "June",
    "Juli": "July",
    "Oktober": "October",
    "Dezember": "December",
    // Spanish months
    "Enero": "January",
    "Febrero": "February",
    "Marzo": "March",
    "Abril": "April",
    "Junio": "June",
    "Julio": "July",
    "Agosto": "August",
    "Septiembre": "September",
    "Octubre": "October",
    "Noviembre": "November",
    "Diciembre": "December",
    // French months
    "Janvier": "January",
    "Février": "February",
    "Mars": "March",
    "Avril": "April",
    "Juin": "June",
    "Juillet": "July",
    "Août": "August",
    "Septembre": "September",
    "Octobre": "October",
    "Novembre": "November",
    "Décembre": "December",
    // Italian months
    "Gennaio": "January",
    "Febbraio": "February",
    "Marzo": "March",
    "Aprile": "April",
    "Maggio": "May",
    "Giugno": "June",
    "Luglio": "July",
    "Agosto": "August",
    "Settembre": "September",
    "Ottobre": "October",
    "Novembre": "November",
    "Dicembre": "December"
};

const Upload = ({onUploadComplete}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selection, setSelection] = useState("");
    const [month, setMonth] = useState("");
    const [quarter, setQuarter] = useState("");
    const [year, setYear] = useState("");
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        return () => {
            toast.dismiss();
        };
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRadioChange = (event) => {
        setSelection(event.target.value);
        setMonth("");
        setQuarter("");
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
    };

    const handleQuarterChange = (event) => {
        setQuarter(event.target.value);
    };

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const onDrop = (acceptedFiles) => {
        setFiles(acceptedFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const currentYear = new Date().getFullYear();
    const years = Array.from(
        { length: Math.max(1, currentYear - 2023) }, 
        (_, i) => 2024 + i
    ).sort((a, b) => b - a);

    const monthMap = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };

    const handleUpload = async () => {
        let isComponentMounted = true;

        if (files.length === 0) {
            toast.error("No file selected", {
                toastId: 'no-file-error'
            });
            return;
        }

        // Create FormData
        const formData = new FormData();
        formData.append('fileType', selection);
        formData.append('year', year);
        const fileName = files[0].name.replace(/\s+/g, '_').trim();
        formData.append('file', fileName);

        if (selection === "monthly") {
            const englishMonth = monthToEnglishMap[month] || month;
            formData.append('month', monthMap[englishMonth]);
        } else if (selection === "quarterly") {
            formData.append('quarter', quarter);
        }

        try {
            setIsLoading(true);
            setMessage("");
            setUploadProgress(0);

            // Get the pre-signed URL
            const response = await axiosInstance.post('/api/v1/data/v2/uploadExcel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (!response.data.data) {
                throw new Error('Failed to get upload URL');
            }

            // Upload file to S3 using the pre-signed URL
            await fetch(response.data.data, {
                method: 'PUT',
                body: files[0],
                headers: {
                    'Content-Type': files[0].type || 'application/octet-stream',
                },
                mode: 'cors'
            });

            // Track upload progress using XMLHttpRequest as fetch doesn't support progress
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', response.data.data);
            xhr.setRequestHeader('Content-Type', files[0].type || 'application/octet-stream');

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable && isComponentMounted) {
                    const percentCompleted = Math.round((event.loaded * 100) / event.total);
                    setUploadProgress(percentCompleted);
                }
            };

            await new Promise((resolve, reject) => {
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve();
                    } else {
                        reject(new Error('Upload failed'));
                    }
                };
                xhr.onerror = () => reject(new Error('Upload failed'));
                xhr.send(files[0]);
            });

            if (isComponentMounted) {
                toast.success("File successfully uploaded. You can see the Processed Data shortly.", {
                    toastId: 'upload-success',
                    autoClose: 3000
                });
                
                if (onUploadComplete) {
                    onUploadComplete();
                }

                // Clear the form
                setFiles([]);
                setSelection("");
                setMonth("");
                setQuarter("");
                setYear("");
                setUploadProgress(0);
                
                handleClose();
            }
        } catch (error) {
            if (isComponentMounted) {
                toast.error(error.response?.data?.message || "Upload failed", {
                    toastId: 'upload-error',
                    autoClose: 3000
                });
                console.error('Upload error:', error);
            }
        } finally {
            if (isComponentMounted) {
                setIsLoading(false);
            }
        }

        return () => {
            isComponentMounted = false;
        };
    };

    const renderUploadButton = () => {
        if (isLoading) {
            return (
                <div className="flex items-center gap-2">
                    <div className="w-6 h-6 border-4 border-t-4 border-white rounded-full animate-spin"></div>
                    {uploadProgress > 0 && <span>{uploadProgress}%</span>}
                </div>
            );
        }
        return t('upload.uploadButton');
    };

    const isPopoverOpen = Boolean(anchorEl);

    return (
        <div className="p-4 relative">
            <button
                onClick={handleClick}
                className={`px-4 py-3 text-sm md:text-base text-white bg-ta--blue transition-all shadow rounded-lg hover:bg-ta--headingBlue`}
            >
                {t('upload.button')}
            </button>

            <Popover
                id={isPopoverOpen ? 'simple-popover' : undefined}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={`upload--box`}
            >
                <div className="p-8 w-[300px] md:w-[500px] lg:w-[600px]">
                    <div
                        {...getRootProps()}
                        className={`mb-4 border-2 border-dashed border-[#1849D6] py-6 md:py-10 px-4 md:px-6 text-center rounded-lg ${isDragActive ? "border-blue-400 bg-blue-50" : "border-gray-300"
                            }`}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <Typography>{t('upload.dragActive')}</Typography>
                        ) : (
                            <>
                                <CloudUploadOutlinedIcon fontSize="large" className="text-[#1849D6] block mx-auto mb-4" />
                                <Typography className="pb-1">
                                    {t('upload.dragText')} <span className="text-[#1849D6] font-bold">{t('upload.browse')}</span>
                                </Typography>
                                <Typography className="text-[#6D6D6D]">{t('upload.fileLimit')}</Typography>
                            </>
                        )}
                    </div>
                    <Typography className="text-[#6D6D6D]">{t('upload.supportedFormats')}</Typography>

                    {files.length > 0 && (
                        <div className="mt-4">
                            <Typography variant="h6">{t('upload.uploadedFiles')}</Typography>
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className="mt-4">
                        <RadioGroup
                            aria-label={t('upload.reportType')}
                            value={selection}
                            onChange={handleRadioChange}
                            style={{ flexFlow: "row" }}
                        >
                            <FormControlLabel
                                className="upload-box--label"
                                value="monthly"
                                control={<Radio />}
                                label={t('upload.monthlyReport')}
                            />
                            <FormControlLabel
                                className="upload-box--label"
                                value="quarterly"
                                control={<Radio />}
                                label={t('upload.quarterlyReport')}
                            />
                        </RadioGroup>
                    </div>

                    {(selection === "monthly" || selection === "quarterly") && (
                        <div className="upload-box--inputs flex items-center justify-between mt-2 bg-white w-full">
                            {selection === "monthly" && (
                                <FormControl fullWidth>
                                    <label>{t('upload.month')}</label>
                                    <Select
                                        className="upload-box--select"
                                        value={month}
                                        onChange={handleMonthChange}
                                    >
                                        {t('upload.months', { returnObjects: true }).map((month, index) => (
                                            <MenuItem key={index} value={month}>
                                                {month}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            {selection === "quarterly" && (
                                <FormControl fullWidth>
                                    <label>{t('upload.quarter')}</label>
                                    <Select
                                        className="upload-box--select"
                                        value={quarter}
                                        onChange={handleQuarterChange}
                                    >
                                        {["Q1", "Q2", "Q3", "Q4"].map((q, index) => (
                                            <MenuItem key={index} value={q}>
                                                {q}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControl fullWidth>
                                <label>{t('upload.year')}</label>
                                <Select
                                    className="upload-box--select"
                                    value={year}
                                    onChange={handleYearChange}
                                >
                                    {years.map((y, index) => (
                                        <MenuItem key={index} value={y}>
                                            {y}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}

                    <div className="mt-4 flex justify-end gap-2">
                        <button
                            className={`px-4 py-3 text-sm md:text-base text-[#666666] bg-[#ECECEC] transition-all shadow rounded-lg hover:bg-[#DDD] upload-box--btn cancel-btn`}
                            onClick={handleClose}
                        >
                            {t('upload.cancel')}
                        </button>
                        <button
                            onClick={handleUpload}
                            className={`px-4 py-3 text-sm md:text-base text-white bg-ta--blue transition-all shadow rounded-lg hover:bg-ta--headingBlue upload-box--btn`}
                            disabled={isLoading}
                        >
                            {renderUploadButton()}
                        </button>
                    </div>

                    {message && (
                        <Typography className={`mt-4 text-center ${isSuccess ? 'text-green-500 font-bold' : 'text-red-500'}`}>
                            {message}
                        </Typography>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default Upload;
