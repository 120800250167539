import React from 'react';
import ContactUs from '../components/contact-us';
import DashboardLayout from '../layouts/dashboard';

const ContactPage = () => {
    return (
        <DashboardLayout>
            <div className="container mx-auto p-5 my-8">
        <div>
            <ContactUs />
        </div>
        </div>
        </DashboardLayout>
    );
};

export default ContactPage;