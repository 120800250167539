import Menu from "../components/menu";

const AuthLayout = (props) => {
    return (
        <>
            <div className={`h-screen overflow-hidden bg-ta--blue`}>
                <Menu type={`auth`} />
                <div className={`auth--container flex items-start justify-center`}>
                    {props.children}
                </div>
                <div className={`footer--border fixed w-full bottom-0 start-0 h-10 bg-ta--yellow`}></div>
            </div>
        </>
    );
}

export default AuthLayout;