import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PlansAndPricing = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Updated monthly plans based on red instructions
 
    const monthlyPlans = [
        {
            title: t('landingpage.plans.plans.free.title'),
            subtitle: t('landingpage.plans.plans.free.subtitle'),
            price: '0',
            currency: '€',
            priceSubTitle: t('landingpage.plans.plans.free.priceSubtitleMonthly'),
            buttonText: t('landingpage.plans.plans.free.buttonText'),
            buttonLink: "/signup",
        },
        {
            title: t('landingpage.plans.plans.starter.title'),
            subtitle: t('landingpage.plans.plans.starter.subtitle'),
            price: '19,90',
            currency: '€',
            priceSubTitle: t('landingpage.plans.plans.starter.priceSubtitleMonthly'),
            buttonText: t('landingpage.plans.plans.starter.buttonText'),
            buttonLink: "#",
        },
        {
            title: t('landingpage.plans.plans.standard.title'),
            subtitle: t('landingpage.plans.plans.standard.subtitle'),
            price: '49,90',
            currency: '€',
            priceSubTitle: t('landingpage.plans.plans.standard.priceSubtitleMonthly'),
            buttonText: t('landingpage.plans.plans.standard.buttonText'),
            buttonLink: "#",

        },
        {
            title: t('landingpage.plans.plans.pro.title'),
            subtitle: t('landingpage.plans.plans.pro.subtitle'),
            price: '99,90',
            currency: '€',
            priceSubTitle: t('landingpage.plans.plans.pro.priceSubtitleMonthly'),
            buttonText: t('landingpage.plans.plans.pro.buttonText'),
            buttonLink: "#",

        },
        {
            title: t('landingpage.plans.plans.enterprise.title'),
            subtitle: t('landingpage.plans.plans.enterprise.subtitle'),
            price: t('landingpage.plans.plans.enterprise.price'),
            currency: "",
            priceSubTitle: t('landingpage.plans.plans.enterprise.priceSubtitleMonthly'),
            buttonText: t('landingpage.plans.plans.enterprise.buttonText'),
            buttonLink: "/contact-us",
        },
    ];

  

    const Box = (props) => {
        let i = props.index;
        let d = props.data;
        return (
            <div key={i} className={`${i % 2 === 0 ? "bg-ta--darkBlue text-white" : "bg-white text-ta--headingBlue shadow-ta--bs h-[97%]"} rounded-3xl px-2 py-12 text-center flex flex-col relative min-h-[500px]`}>
                <div>
                    <p className={`font-bold text-lg md:text-3xl mb-4`}>{d.title}</p>
                    <p className={`text-sm md:text-lg text-ta--textGrey mb-5 whitespace-pre-line`}>{d.subtitle}</p>
                </div>
                <div className="absolute bottom-24 left-0 right-0">
                    <div className={`${i % 2 === 0 ? "bg-white" : "bg-ta--grey"} block mx-auto w-4/6 h-0.5 mb-5`}></div>
                    {d.price ? (
                        <>
                            <p className={`font-bold text-lg md:text-3xl mb-1`}>{d.price}{d.currency}</p>
                            <p className={`text-sm md:text-lg text-ta--textGrey mb-8`}>{d.priceSubTitle}</p>
                        </>
                    ) : (
                        <>
                            <p className={`font-bold text-lg md:text-3xl mb-1`}>{d.price}{d.currency}</p>
                            <p className={`text-sm md:text-lg text-ta--textGrey mb-8`}>{d.priceSubTitle}</p>
                        </>
                    )}
                </div>
                <div className="absolute bottom-12 left-0 right-0">
                    <Link 
                        to={d.buttonLink} 
                        onClick={() => handlePlanSelection(d)}
                        className={`${i % 2 === 0 ? "bg-transparent border-yellow hover:bg-white hover:text-ta--darkBlue" : "bg-ta--yellow border-ta--yellow hover:bg-white hover:text-ta--yellow"} block max-w-[150px] mx-auto p-2 border text-sm text-white rounded-full transition-all`}
                    >
                        {d.buttonText}
                    </Link>
                </div>
            </div>
        );
    };

    const handlePlanSelection = (plan) => {
        // Map translated plan titles to English plan names
        const planNameMap = {
            [t('landingpage.plans.plans.free.title')]: 'Free',
            [t('landingpage.plans.plans.starter.title')]: 'Basic',
            [t('landingpage.plans.plans.standard.title')]: 'Standard',
            [t('landingpage.plans.plans.pro.title')]: 'Pro',
            [t('landingpage.plans.plans.enterprise.title')]: 'Enterprise'
        };

        // Get English plan name from the map
        const englishPlanName = planNameMap[plan.title] || plan.title;

        // Save selected plan to localStorage with English plan name
        const subscriptionData = {
            plan: englishPlanName,
            price: plan.price,
            active: false
        };
        localStorage.setItem('userSubscription', JSON.stringify(subscriptionData));

        // Check if user is logged in (you'll need to adjust this based on how you store login state)
        const isLoggedIn = localStorage.getItem('token'); // or however you check login status
        
        if (isLoggedIn) {
            // If logged in, redirect directly to billing
            window.location.href = '/billing';
        } else {
            // If not logged in, save redirect and go to signup
            localStorage.setItem('redirectAfterLogin', '/billing');
            window.location.href = '/signup';
        }
    };

    return (
        <div className="bg-white py-8">
            <div className="container mx-auto p-5">
                <p className="text-ta--headingBlack font-bold text-2xl md:text-4xl mb-5 md:mb-8">{t('landingpage.plans.title')}</p>
                <div className="flex flex-col md:flex-row items-start justify-between gap-4 mb-4">
                    <p className="text-ta--headingBlack font-medium text-base md:text-xl mb-5 md:mb-8">{t('landingpage.plans.description')}</p>
                    {/* <div className="w-full md:w-[40%]">
                        <Tabs className="plans--tabs" value={activeTab} onChange={handleTabChange}>
                            <Tab label={t('landingpage.plans.tabs.monthly')} />
                            <Tab label={t('landingpage.plans.tabs.yearly')} />
                        </Tabs>
                    </div> */}
                </div>
                <div className="mb-8">
                    {activeTab === 0 &&
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-6 lg:gap-8">
                            {monthlyPlans?.map((row, index) => (
                                <Box data={row} index={index} key={index} />
                            ))}
                        </div>
                    }
                   
                </div>
            </div>
        </div>
    );
}

export default PlansAndPricing;
