import Footer from "../components/footer";
import Menu from "../components/menu";

const CommonLayout = (props) => {
    return (
        <>
            <div className={`bg-ta--white`}>
                <Menu type={`common`} />
                {props.children}
            </div>
            <Footer />
        </>
    );
}

export default CommonLayout;