// CountrySalesBarChart.js

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function getCategoryTotal(countryObj, categoryName) {
  // Looks for the transactionCategory matching this categoryName:
  const foundCat = countryObj?.transactionCategories?.find(
    (catObj) => catObj.category === categoryName
  );
  // Return the total from the "ALL" array if it exists; otherwise 0
  return foundCat?.transactions?.ALL?.[0]?.total ?? 0;
}

const CountrySalesBarChart = ({ data }) => {
  const { t } = useTranslation();
  // 1) Collect all unique category names across all countries:
  const categoriesSet = new Set();
  data?.forEach((country) => {
    country?.transactionCategories?.forEach((catObj) => {
      categoriesSet.add(catObj.category);
    });
  });
  // Convert set to array
  const categories = Array.from(categoriesSet);

  // 2) Prepare data for chart:
  const labels = data?.map((countryObj) => countryObj.country);

  // Define a palette for however many categories you might have
  // (cycle through if you have more categories than colors).
  const colorPalette = [
    '#4da6ff',  // light-ish blue
    '#D3C033',  // warm yellow
    '#0099cc',  // teal-ish blue
    '#bbc1cc',  // subtle gray/blue
    '#66b3ff',  // extra blues, etc.
    '#ffd966'
  ];

  // Build one dataset per category:
  const datasets = categories?.map((cat, idx) => {
    const totals = data?.map((countryObj) => getCategoryTotal(countryObj, cat));
    return {
      label: cat,
      data: totals,
      backgroundColor: colorPalette[idx % colorPalette.length]
    };
  });

  const chartData = {
    labels,
    datasets
  };

  // 3) Chart options (horizontal orientation, etc.):
  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          // If you need a currency display, prepend or append '€' as needed
          callback: function (value) {
            return `${value} €`;
          }
        }
      }
    },
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: t('graphics.profitOverview', 'Sales Overview') }
    }
  };

  return (
    <div style={{ width: '100%', height: 500 }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default CountrySalesBarChart;
