import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import AuthLayout from "../layouts/auth";
import axiosInstance from "../services/axiosConfig";

const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    
    // Remove the complex password validation state
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

    useEffect(() => {
        // Extract token from URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const tokenFromUrl = queryParams.get("token");
        console.log(tokenFromUrl);
        if (!tokenFromUrl) {
            setError(t('resetPassword.invalidToken'));
            return;
        }
        
        setToken(tokenFromUrl);
    }, [location, t]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Check if passwords match
    const checkPasswordsMatch = (pass, confirmPass) => {
        const match = pass === confirmPass;
        setPasswordsMatch(match);
        return match;
    };

    // Update password handler with only match validation
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        if (confirmPasswordTouched) {
            checkPasswordsMatch(newPassword, confirmPassword);
        }
        if (!passwordTouched) {
            setPasswordTouched(true);
        }
    };

    // Update confirm password handler with only match validation
    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        checkPasswordsMatch(password, newConfirmPassword);
        if (!confirmPasswordTouched) {
            setConfirmPasswordTouched(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Only check if passwords match before submission
        const doPasswordsMatch = checkPasswordsMatch(password, confirmPassword);
        
        if (!doPasswordsMatch) {
            setError(t('resetPassword.passwordsDoNotMatch'));
            return;
        }
        
        setLoading(true);
        setError("");
        
        try {
            // Use axiosConfig.post to call the reset password API
            const response = await axiosInstance.post(
                `/api/v1/auth/reset-password?token=${token}&newPassword=${password}`
            );
            
            if (response.status === 200) {
                setSuccess(true);
                // Redirect to login after 3 seconds
                setTimeout(() => {
                    navigate('/signin');
                }, 3000);
            } else {
                setError(t('resetPassword.error'));
            }
        } catch (err) {
            setError(err.response?.data?.message || t('resetPassword.error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthLayout>
            <div className="container mx-auto px-5">
                <div className="border border-white rounded-[2rem] bg-[#FFFFFF50] px-[2rem] md:px-[3.5rem] lg:px-[5rem] py-[1.25rem] md:py-[2rem] lg:py-[3rem] w-full max-w-[300px] md:max-w-[500px] lg:max-w-[700px] mx-auto">
                    {success ? (
                        <div className="text-center">
                            <p className="text-white font-bold text-lg md:text-xl lg:text-3xl mb-6">
                                {t('resetPassword.success')}
                            </p>
                            <p className="text-white">
                                {t('resetPassword.redirecting')}
                            </p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <p className="text-white font-bold text-lg md:text-xl lg:text-3xl mb-6 md:mb-5 lg:mb-8">
                                {t('resetPassword.title')}
                            </p>

                            {error && <p className="text-red-500 mb-4">{error}</p>}

                            <div className="mb-4 md:mb-6">
                                <label className="text-base md:text-lg text-white font-bold block">
                                    {t('resetPassword.passwordLabel')}
                                </label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        onBlur={() => setPasswordTouched(true)}
                                        className="border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base focus:outline-none pr-10"
                                        required
                                    />
                                    <button 
                                        type="button" 
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div className="mb-6 md:mb-8">
                                <label className="text-base md:text-lg text-white font-bold block">
                                    {t('resetPassword.confirmPasswordLabel')}
                                </label>
                                <div className="relative">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        onBlur={() => setConfirmPasswordTouched(true)}
                                        className={`border-b-[3px] ${confirmPasswordTouched && !passwordsMatch ? 'border-red-500' : 'border-white'} w-full bg-transparent text-white text-sm md:text-base focus:outline-none pr-10`}
                                        required
                                    />
                                    <button 
                                        type="button" 
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white"
                                        onClick={toggleConfirmPasswordVisibility}
                                    >
                                        {showConfirmPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                                <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                                <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                                
                                {/* Password match indicator */}
                                {confirmPasswordTouched && confirmPassword && (
                                    <p className={`mt-1 text-sm ${passwordsMatch ? 'text-green-400' : 'text-red-400'}`}>
                                        {passwordsMatch 
                                            ? t('resetPassword.passwordMatch') 
                                            : t('resetPassword.passwordsDoNotMatch')}
                                    </p>
                                )}
                            </div>

                            <button
                                type="submit"
                                disabled={loading || !token || !passwordsMatch}
                                className={`block mx-auto px-[3rem] md:px-[5rem] lg:px-[8rem] py-2 rounded-lg ${
                                    loading || !token || !passwordsMatch
                                        ? 'bg-gray-400 border-gray-400 cursor-not-allowed'
                                        : 'bg-ta--yellow border border-ta--yellow hover:bg-transparent hover:text-ta--yellow'
                                } text-white text-sm md:text-base font-bold transition-all`}
                            >
                                {loading ? t('resetPassword.loading') : t('resetPassword.submitButton')}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </AuthLayout>
    );
};

export default ResetPassword; 