import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import AuthLayout from "../layouts/auth";
import { login } from "../services/auth"; // API call

const Signin = () => {
    const { t } = useTranslation(); // Hook to handle translations
    const navigate = useNavigate(); // For redirection
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [maintainSession, setMaintainSession] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRedirectAfterLogin = (userData) => {
        const redirectPath = localStorage.getItem('redirectAfterLogin');
        const subscription = userData.subscription || { active: false };
        localStorage.removeItem('redirectAfterLogin');
        if(!userData.userSubscription.active){   
            navigate('/billing');
        }else{
            navigate(redirectPath || '/graphics');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        const credentials = { email, password };
        try {
            const response = await login(credentials);
            if (response.statusCode === 200) {
                localStorage.setItem("token", response.data.jwtToken);
                localStorage.setItem("isLoggedIn", 'true');
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("userData", JSON.stringify(response.data));
                
                if (maintainSession) {
                    sessionStorage.setItem("token", response.data.jwtToken);
                    sessionStorage.setItem("username", response.data.username);
                    sessionStorage.setItem("userData", JSON.stringify(response.data));
                }
                
                // Handle redirect based on subscription status
                handleRedirectAfterLogin(response.data);
            } else {
                setError(t('signin.error'));
            }
        } catch (err) {
            setError(err.response?.data?.message || t('signin.error'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <AuthLayout>
                <div className={`container mx-auto px-5`}>
                    <div className={`border border-white rounded-[2rem] bg-[#FFFFFF50] px-[2rem] md:px-[3.5rem] lg:px-[5rem] py-[1.25rem] md:py-[2rem] lg:py-[3rem] w-full max-w-[300px] md:max-w-[500px] lg:max-w-[700px] mx-auto`}>
                        <form onSubmit={handleSubmit}>
                            <p className={`text-white font-bold text-lg md:text-xl lg:text-3xl mb-6 md:mb-5 lg:mb-8`}>
                                {t('signin.title')}
                            </p>

                            {error && <p className="text-red-500">{error}</p>}

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signin.emailLabel')}
                            </label>
                            <input
                                autoComplete="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-4 md:mb-6 focus:outline-none`}
                            />

                            <label className={`text-base md:text-lg text-white font-bold block`}>
                                {t('signin.passwordLabel')}
                            </label>
                            <input
                                autoComplete="new-password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`border-b-[3px] border-white w-full bg-transparent text-white text-sm md:text-base mb-3 md:mb-4 focus:outline-none`}
                            />

                            <div className={`mb-6 flex items-start gap-2 md:items-center justify-start md:justify-between flex-col md:flex-row`}>
                                <label className={`text-ta--grey text-sm flex items-center justify-start gap-1`}>
                                    <input type="checkbox" checked={maintainSession} onChange={(e) => setMaintainSession(e.target.checked)} />
                                    {t('signin.rememberMe')}
                                </label>
                                <Link className={`text-ta--grey text-[10px] transition-all hover:underline`} to={`/forgot-password`}>
                                    {t('signin.forgotPassword')}
                                </Link>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className={`block mx-auto px-[3rem] md:px-[5rem] lg:px-[8rem] py-2 rounded-lg bg-ta--yellow border border-ta--yellow text-white text-sm md:text-base font-bold transition-all hover:bg-transparent hover:text-ta--yellow`}
                            >
                                {loading ? t('signin.loading') : t('signin.submitButton')}
                            </button>
                        </form>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Signin;
