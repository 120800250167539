import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Modal from 'react-modal';

const useAuthFromLocalStorage = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const userSubscriptionStr = localStorage.getItem('userSubscription');
    const hasActiveSubscription = userSubscriptionStr 
        ? JSON.parse(userSubscriptionStr).active 
        : false;

    return { isLoggedIn, hasActiveSubscription };
};

const Banner = () => {
    const { t, i18n } = useTranslation();
    const { isLoggedIn, hasActiveSubscription } = useAuthFromLocalStorage();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    // Map language codes to YouTube video IDs
    const videoIdMap = {
        es: 'qot0VF33fQg', // Spanish
        en: 'YcK-Dq6wkpQ', // English
        de: 'qRLJ1AlvyOM', // German
        fr: 'zW__8MAFeCA', // French
        it: 'sUicR--zKiY'  // Italian
    };

    // Get current language and fallback to English if not found
    const currentLanguage = i18n.language.substring(0, 2);
    const videoId = videoIdMap[currentLanguage] || videoIdMap.en;

    return (
        <>
            <div className="banner--div relative min-h-[500px] mb-12 lg:pb-[200px] pb-[150px]">
                <img
                    src={`/main-screen.png`}
                    className="hidden md:block absolute end-0 top-0 md:max-h-[400px] md:max-w-[50%] lg:max-h-[500px]"
                    alt=""
                />
                <div className="container mx-auto p-5">
                    <div className="w-full md:w-[50%]">
                        <p className="text-white font-bold text-xl md:text-3xl lg:text-5xl sm:leading-tight mb-4">
                            {t('landingpage.hero.title')}
                        </p>
                        <p className="text-[#8794BA] text-sm md:text-xl lg:leading-tight sm:leading-tight mb-8">
                            {t('landingpage.hero.description')}
                        </p>
                        <p className="text-[#8794BA] text-sm md:text-xl lg:leading-tight sm:leading-tight mb-8">
                            {t('landingpage.hero.benefit')}
                        </p>
                        <div className="flex flex-col items-center justify-between gap-3 pb-12">
                            <button
                                className="w-full text-center px-[2rem] md:px-[3rem] lg:px-[5rem] py-2 rounded-full border border-ta--yellow text-ta--yellow text-[25px] md:text-[25px] transition-all hover:bg-ta--yellow hover:text-white"
                                onClick={openModal}
                            >
                                {t('landingpage.hero.demo_video')}
                            </button>
                            <Link
                                className="w-full flex flex-col items-center justify-center px-[3px] py-[3px] rounded-full bg-ta--yellow border border-ta--yellow text-white text-[20px] md:text-[20px] transition-all hover:bg-transparent hover:text-ta--yellow"
                                to={
                                    isLoggedIn 
                                        ? (hasActiveSubscription 
                                            ? '/graphics' 
                                            : '/billing')
                                        : '/signup'
                                }
                            >
                                <div className="text-[25px] md:text-[25px]">{t('landingpage.hero.start_trial')}</div>
                                <div className="text-[20px] md:text-[20px]">{t('landingpage.hero.start_trial_description')}</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <img src={`/main-screen.png`} className="md:hidden block" alt="" />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Demo Video"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <button onClick={closeModal} className="close-button">Close</button>
                <iframe
                    width="100%"
                    height="400px"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </Modal>
        </>
    );
};

export default Banner;
