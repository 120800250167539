import axios from 'axios';
import { getToken } from './auth';  // Import the token function from your auth module

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,  // Base URL from environment variable
  withCredentials: true, 
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  headers: {
    'Referrer-Policy': 'unsafe-url'  // This is the equivalent of referrerPolicy
    
  }
});

// Add a request interceptor to inject token dynamically
api.interceptors.request.use(
  (config) => {
    const token = getToken();  // Fetch token from your auth module
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;  // Add token to Authorization header
    }
    return config;  // Return the modified config object
  },
  (error) => {
    return Promise.reject(error);  // Return the error if request fails
  }
);

// Add response interceptor to handle common responses
api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  // Handle errors globally
  if (error.response && error.response.status === 401) {
    // Handle unauthorized access
    // Example: redirect to login
  }
  return Promise.reject(error);
});

export default api;
